import randomInt from 'random-int';
import moment from 'moment';
import {Weet} from '@/store/weet/weetModel';
import slugify from '@sindresorhus/slugify';
// @ts-ignore
import linkifyHtml from 'linkifyjs/html';
import xss from 'xss';
import download from 'downloadjs';
import {User} from '@/store/user/userModel';
import {Media} from '@/store/media/mediaModel';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import {Workspace} from '@/store/workspace/workspaceModel';
import store from "@/store";
import axios from "axios";
import {STORE_DEDICATED_TENANT} from "@/store/auth/authAction";
import {isSpeach} from "@/utils/speachUtils";


export const isURL = (urlString: string) => {
    let url;
    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}
export const isUrlExists = async (url): Promise<boolean> => {
    // Warining need coors authorisation
    return axios.head(url).then(() => {
        return true;
    }).catch(() => {
        return false;
    });
}

const IMAGE_EXIST_CACHE: {} = {};
export const isImageExists = async (url): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (IMAGE_EXIST_CACHE[url]) {
            return resolve(true);
        }
        const img = new Image();
        img.src = url;
        img.onerror = () => {
            resolve(false)
        }
        img.onload = () => {
            IMAGE_EXIST_CACHE[url] = true;
            resolve(true);
        }
    })
}

export const generateRandomString = (length: number): string => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXY0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const capitalize = (word: string): string => {
    if (!word) {
        return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
};

export const isEmail = (search: string): boolean => {

    const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return regexp.test(search);
};

export const getTenantID = (): string => {
    if (isSpeach()) {
        const urlParams = new URLSearchParams(window.location.search);
        // Check if there is a boutiqueID parameter in the URL
        const dedicatedTenant = urlParams.get('dedicatedTenant');
        if (dedicatedTenant) {
            store.dispatch(STORE_DEDICATED_TENANT, dedicatedTenant)
            return dedicatedTenant;
        } else if (store.getters.getDedicatedTenant) {
            return store.getters.getDedicatedTenant;
        }
    } else {
        const hostname: string = document.location.hostname;
        const subdomainArray: string[] = hostname.split('.');
        if (subdomainArray.length > 1) {
            return subdomainArray[0];
        } else {
            return 'master';
        }
    }
    return 'master'
};

export const getTabOfColor = (hex: boolean = false): string[] => {
    if (hex) {
        return ['#5AAEFA', '#F1C232', '#00CD94', '#F8550F'];
    } else {
        return ['color3', 'color2', 'color5', 'color4'];
    }
};
// Must be synchronise with the theme
export const getRandomColor = (hex: boolean = false): string => {
    const colors = getTabOfColor(hex);
    return colors[randomInt(colors.length - 1)];
};


export const getLocalTime = (date: Date, format: string = 'LL LT'): string => {
    return moment(date).format(format);
};
export const getDateAgo = (date: Date | number): string => {
    return moment(date).fromNow();
};

export const getUserAvatar = (user: User | undefined | null, size: number): string => {
    if (user && user.imageURL) {
        return getImageURLForSize(user.imageURL, size);
    } else {
        if (size < 100) {
            return require('@/assets/avatar_min.jpg');
        } else {
            return require('@/assets/avatar.jpg');
        }
    }
};

export const getWorkspaceAvatar = (workspace: Workspace | null, size: number): string => {
    if (workspace && workspace.imageURL) {
        return getImageURLForSize(workspace.imageURL, size);
    } else {
        if (size < 100) {
            return require('@/assets/avatar_min.jpg');
        } else {
            return require('@/assets/avatar.jpg');
        }
    }
};

export const getImageURLForSize = (imageURL: string, size: number): string => {
    if (imageURL === null) {
        return '';
    } else {
        // if data url
        if (imageURL.indexOf('data') === 0) {
            return imageURL;
        }
        return store.getters.getCdnImage + '/' + imageURL + '/' + size;
    }
};


export const getVirtualScreenVideoURL = (svb: ScreenVirtualBackgroundEnum | string): string => {
    return getVideoURLForVB('screenVB/' + svb, 'HD');
};

export const getVirtualScreenVideoThumbnail = (svb: ScreenVirtualBackgroundEnum | string, staticThumb: boolean = true): string => {
    return getThumbnailURL('screenVB/' + svb, staticThumb);
};


export const getVideoURLForStatus = (media: Media): string => {
    if (media.cdnMediaUrl === null || media.status !== 'HD') {
        if (media.blobURL) {
            return media.blobURL;
        } else {
            return '';
        }
    } else {
        if (media.cdnMediaUrl) {
            return media.cdnMediaUrl;
        } else {
            return '';
        }
    }
};

export const getVideoURLForVB = (jobId: string, status: string): string => {
    if (jobId === null) {
        return '';
    } else {
        return store.getters.getCdnVideo + '/' + getTenantID() + '/media/' + jobId + '/' + status + '.mp4';
    }
};

export const isMediaNeedRefresh = (media: Media): boolean => {
    if (media.status === 'ERROR') {
        throw new Error("Impossible to refresh the status of the media... The media has error")
    }
    if (media.status === 'WAITING') {
        return true;
    } else {
        return false;
    }
};

export const getThumbnailURL = (jobId: string, staticThumb: boolean = true): string => {
    if (jobId === null) {
        return '';
    } else {
        let thumb = 'thumbnail.gif';
        if (staticThumb) {
            thumb = 'thumbnail.jpg';
        }
        return store.getters.getCdnVideo + '/' + getTenantID() + '/media/' + jobId + '/' + thumb;
    }
};

export const getWeetURL = (weet: Weet): string => {
    if (weet) {
        return document.location.origin + '/play/' + weet.weetID + '/' + slugify(weet.title);
    } else {
        return '';
    }
};

export const htmliFy = (value: string): string => {
    const html = linkifyHtml(value, {
        defaultProtocol: 'https',
    });
    return xss(replaceStringLineBreakByBr(html), {
        whiteList: {
            a: ['href', 'title', 'target'],
            br: [],
            b: [],
            i: []
        }
    });
};

/**
 * Replace all '\n' in input string by '<br>
 * @param input, the input string
 */
export const replaceStringLineBreakByBr = (input: string): string => {
    return input?.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const downloadUrl = async (url: string, filename: string, contentType: string): Promise<void> => {
    return new Promise((resolve, reject) => {
            const x = new XMLHttpRequest();

            x.open('GET', url, true);
            x.responseType = 'blob';
            x.onload = (e) => {
                // @ts-ignore
                download(e.target.response, slugify(filename), contentType);
                resolve();
            };
            x.send();
        }
    );
};

export const getUrlContent = async (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
            const x = new XMLHttpRequest();

            x.open('GET', url, true);
            x.responseType = 'text';
            x.onload = (e) => {
                // @ts-ignore
                resolve(e.target.response);
            };
            x.send();
        }
    );
};
export const checkUrlExists = async (url: string): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            const headRequest = new XMLHttpRequest();

            headRequest.open('HEAD', url, true);
            headRequest.onload = () => {
                if (headRequest.status >= 200 && headRequest.status < 400) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            };
            headRequest.onerror=(e)=>{
                resolve(false)
            }
            headRequest.send();
        });
}


export const parseJwt = (token): any => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const isJwtExpire=(token:string)=>{
    const decodedToken = parseJwt(token);

    if (!decodedToken || !decodedToken.exp) {
        return true;  // If the token doesn't have an exp field, treat it as expired
    }
    // Get the current time in seconds (JWT 'exp' is in seconds)
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare current time with the expiration time
    return decodedToken.exp < currentTime;
}
