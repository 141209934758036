<template>
  <div class="titleSectionContainer">

    <div ref="titleDiv" class="titleEditable" :class="placeHolder?'placeHolder':''" :contenteditable="true"
         v-text="titleOfSection" @focusin="removePlaceHolder"
         @focusout="changeTitleSection" @keydown.enter="focusOutTitleEdition">
    </div>
  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {
  getFreeTimeAfterEvent,
  getTimeEventbeforeTime,
  getTitleTimeEventEvent
} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import WInput from '@/components/wrapper/w-input.vue';
import {Weet} from '@/store/weet/weetModel';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import store from '@/store';
import {
  ADD_TIME_EVENT,
  DELETE_TIME_EVENT,
  SAVE_TIMELINE_IF_NEED,
  TIMELINE_UNSAVED
} from '@/store/timeLine/timeLineAction';
import WButton from '@/components/wrapper/w-button.vue';
import {inform} from '@/utils/dialog';
import {clone} from "@/utils/cloneUtil";

@Component({
  components: {WButton, WInput, WIcon}
})
export default class SectionTitle extends Vue {
  @Prop({default: 0})
  private time!: number;

  @Prop()
  private weet!: Weet;

  private placeHolder: boolean = true;

  get titleOfSection(): string {
    if (this.weet) {
      const mediaEvent = getTimeEventbeforeTime(this.weet.timeLine, this.time + 1, TimeEventType.MEDIA_PLAY) as TimeEvent;
      const titleEvent = getTitleTimeEventEvent(this.weet.timeLine, mediaEvent);
      if (titleEvent) {
        this.placeHolder = false;
        return titleEvent.value;
      } else {
        this.placeHolder = true;
        return this.$t('sectionTitle.placeholder').toString();
      }
    }
    return '';
  }

  private focusOutTitleEdition(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

  private removePlaceHolder(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      const title = element.innerText.trim();
      if (title === this.$t('sectionTitle.placeholder').toString()) {
        element.innerText = '';
      }
      this.placeHolder = false;
    }
  }


  public changeTitleSection(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      let updated = false;
      if (element.innerText === this.$t('sectionTitle.placeholder').toString()) {
        return;
      } else {
        let title = element.innerText.trim();
        if (title.length > 200) {
          title = title.substr(0, 199);
        }
        const mediaEvent = getTimeEventbeforeTime(this.weet.timeLine, this.time + 1, TimeEventType.MEDIA_PLAY) as TimeEvent;

        let titleEvent = getTitleTimeEventEvent(this.weet.timeLine, mediaEvent);
        if (!title) {
          if (titleEvent) {
            // delete the event
            store.dispatch(DELETE_TIME_EVENT, titleEvent);
            store.dispatch(TIMELINE_UNSAVED);
            store.dispatch(SAVE_TIMELINE_IF_NEED);
            updated = true;
          }
          element.innerText = this.$t('sectionTitle.placeholder').toString();
          this.placeHolder = true;
        } else {

          if (titleEvent) {
            if (titleEvent.value.trim() !== title) {
              titleEvent = clone(titleEvent) as TimeEvent;
              titleEvent.value = title;
              store.dispatch(ADD_TIME_EVENT, titleEvent);
              store.dispatch(TIMELINE_UNSAVED);
              store.dispatch(SAVE_TIMELINE_IF_NEED);
              updated = true;
            }
          } else {
            if (mediaEvent) {
              // now we dupplicate the mediaEvnet
              const chapterTitleEvent = clone(mediaEvent);
              chapterTitleEvent.type = TimeEventType.CHAPTER_TITLE;
              chapterTitleEvent.time = getFreeTimeAfterEvent(this.weet.timeLine, mediaEvent.time);
              chapterTitleEvent.value = title;
              store.dispatch(ADD_TIME_EVENT, chapterTitleEvent);
              store.dispatch(TIMELINE_UNSAVED);
              store.dispatch(SAVE_TIMELINE_IF_NEED);
              updated = true;
            }
          }
        }
        if (updated) {
          inform(this.$t('sectionTitle.success').toString());
        }


      }
    }

  }
}
</script>


<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.titleSectionContainer {
  display: flex;

  .titleEditable {
    font-size: 18px;
    font-weight: 900;
    flex-grow: 1;
    color: var(--black);
    text-align: left;
    justify-content: center;
    line-height: 32px;
    margin-left: 0px;

    &:hover {
      box-shadow: 0px 0px 2px var(--light2);
    }

    &:focus {
      box-shadow: 0px 0px 2px var(--primary);
    }

    &.placeHolder {
      color: var(--light2);
    }

  }
}

</style>
