<template>
  <div class="weetAdvancedEditingThumbnailContainer">
    <div class="colContainer">
      <Transition name="zoom">
        <div class="colLeft">
          <perfect-scrollbar class="thumbnailOptionContainer" ref="scrollPanel">
            <div class="optionLineTitle">
              {{$t('createWeetComponent.advancedEditing.thumbnailMode.option')}}
            </div>
            <div class="uploadElement">
              <img class="uploadPreviewElement" ref="imageBackground" v-if="urlUpload" :src="urlUpload"
                   width="1280"
                   height="720"/>
              <div class="trashIcon" v-if="urlUpload">
                <w-button icon="delete" color="outlined" size="small" @click="removeUpload"/>
              </div>
              <ThumbnailCropEditor @blob="onImageUpoaded"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.showTitle')}}
              </div>
              <w-switch v-model="showTitle" size="small" @input="generateThumbnail"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.showTime')}}
              </div>
              <w-switch v-model="showTime" size="small" @input="generateThumbnail"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.playIcon')}}
              </div>
              <w-switch v-model="isPlayIcon" size="small" @input="generateThumbnail"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.blurBackground')}}
              </div>
              <w-switch v-model="isBlurBackground" size="small" @input="generateThumbnail"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.removeSticker')}}
              </div>
              <w-switch v-model="displaySticker" size="small" @input="generateThumbnail"/>
            </div>
            <div class="optionLine">
              <div class="labelOption">
                {{$t('createWeetComponent.advancedEditing.thumbnailMode.profilSticker')}}
              </div>
              <w-switch v-model="avatarInSticker" size="small" @input="generateThumbnail"/>
            </div>
          </perfect-scrollbar>
          <div class="whiteFade"></div>
        </div>
      </Transition>
      <div class="colRight">
        <TimeLineSelector ref="timeSelector" :weet="weet"
                          @click="changePlayerTimeAndGenerateThumnbail">
        </TimeLineSelector>
      </div>
    </div>
    <div class="thumbnailPreview" @click="openImageInTab()" :style="'background-image:url('+currentThumbnail+')'">
      <b-loading v-model="thumbnailInProgress" :is-full-page="false" :can-cancel="false"/>
      <div class="previewHover">
        <w-icon icon="magnify-plus-outline" color="dark" size="large"/>
      </div>
    </div>
    <div class="buttonElement">
      <w-button size="default" @move="back()" :weet="weet"
                @click="saveThumbnail()" :loading="uploadInProgress" :upgrade-mode="!isPremiumWorkspace">
        {{$t('createWeetComponent.advancedEditing.thumbnailMode.save')}}
      </w-button>
    </div>

    <w-modal class="thumnbailLargePreview" :active.sync="showPopUpThumbnailLarge">
      <div class="thumbnailLargeImage">
        <img :src="currentThumbnail">
      </div>
    </w-modal>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import {PREVIEW_MODE, TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {SEEK_PLAYER, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import {
  getDurationOfTimeLine,
} from '@/utils/timeLineUtils';
import prettyMilliseconds from 'pretty-ms';
import {setPageEvent} from '@/utils/tracker';
import {PageEvent} from '@/enum/TrackerEnum';
import {getImageURLForSize} from '@/utils/util';
import {ThumbnailGenerator} from '@/utils/video/videoEffect/ThumbnailGenerator';
import debounce from 'lodash/debounce';
import WSwitch from '@/components/wrapper/w-switch.vue';
import UpgradePlanButton from '@/components/upgradePlan/UpgradePlanButton.vue';
import UpgradePlanTagButton from '@/components/upgradePlan/UpgradePlanTagButton.vue';
import {PaymentPlanEnum, Workspace} from '@/store/workspace/workspaceModel';
import {UPDATE_MY_WEET_IMAGE} from '@/store/myWeet/myWeetAction';
import WModal from '@/components/wrapper/w-modal.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {inform} from '@/utils/dialog';
import ThumbnailCropEditor from '@/components/recorder/thumbnailGenerator/ThumbnailCropEditor.vue';
import delay from 'delay';


@Component({
  components: {
    ThumbnailCropEditor,
    WIcon,
    WModal,
    UpgradePlanTagButton,
    UpgradePlanButton,
    WSwitch,
    ProgressProcessPlayer, TimeLineSelector, TimeLine, EndWeetButton, WButton, WError, WInput, WeetPlayer
  },
})
export default class CreateWeetThumbnailMode extends Vue {

  @Prop()
  private weet!: Weet;

  // Upload feature prop
  private urlUpload: string = '';

  // url of the thumbnail generated
  private currentThumbnail: string = '';
  private thumbnailInProgress: boolean = false;
  private tumbnailGenerator!: ThumbnailGenerator;
  private thumbnailModified: boolean = false;

  private isBlurBackground: boolean = false;
  private isPlayIcon: boolean = true;
  private avatarInSticker: boolean = false;
  private displaySticker: boolean = true;
  private showTitle: boolean = false;
  private showTime: boolean = false;

  private isPlayerInit: boolean = false;
  private blob: Blob | null = null;
  private uploadInProgress: boolean = false;

  private showPopUpThumbnailLarge: boolean = false;

  get selectedWorkspace(): Workspace | undefined {
    return store.getters.getSelectedWorkspace;
  }

  get timerPlayer() {
    return store.getters.getTimerPlayer;
  }


  get isPremiumWorkspace(): boolean {
    if (this.selectedWorkspace) {
      return this.selectedWorkspace.plan !== PaymentPlanEnum.FREE;
    } else {
      return false;
    }
  }

  get durationOfTimeLine() {
    return getDurationOfTimeLine(this.weet.timeLine);
  }

  private mounted() {
    this.cancelThumbnailModification();
    this.tumbnailGenerator = new ThumbnailGenerator();
    setPageEvent(PageEvent.page_record_step_advanced_thumbnail);
  }

  private async onImageUpoaded(blob: Blob) {
    this.urlUpload = URL.createObjectURL(blob);
    this.$nextTick(() => {
      this.generateThumbnail();
    });
  }

  private removeUpload() {
    this.urlUpload = '';
    this.$nextTick(() => {
      this.generateThumbnail();
    });
  }

  private getSimpleTime(time: number) {
    return prettyMilliseconds(time, {colonNotation: true, secondsDecimalDigits: 0});
  }

  private back() {
    this.$emit('back');
  }

  private openImageInTab() {
    this.showPopUpThumbnailLarge = true;
    // open(this.currentThumbnail, 'weetImg');
  }

  @Watch('timerPlayer')
  private debounceWeetsFilter = debounce(() => {
    if (this.isPlayerInit) {
      this.generateThumbnail();
    } else {
      this.isPlayerInit = true;
    }
  }, 250);

  private async generateThumbnail() {
    if (this.thumbnailInProgress) {
      return;
    }
    this.thumbnailInProgress = true;
    this.thumbnailModified = true;
    try {
      this.blob = await this.tumbnailGenerator.generateThumbnailForWeet(this.weet, this.timerPlayer,
          {
            blur: this.isBlurBackground,
            avatarInSticker: this.avatarInSticker,
            removeSticker: !this.displaySticker,
            showTitle: this.showTitle,
            showTime: this.showTime,
            playIcon: this.isPlayIcon,
            background: this.$refs.imageBackground as HTMLImageElement
          });
      this.currentThumbnail = URL.createObjectURL(this.blob);
      this.thumbnailInProgress = false;
    } catch (e) {
      console.warn(e);
      this.thumbnailInProgress = false;
      this.thumbnailModified = false;
    }
  }

  private async saveThumbnail() {
    if (this.blob) {
      this.uploadInProgress = true;
      await store.dispatch(UPDATE_MY_WEET_IMAGE, {weetID: this.weet.weetID, blob: this.blob, fix: true});
      inform(this.$t('createWeetComponent.advancedEditing.thumbnailMode.success').toString());
      this.uploadInProgress = false;
      this.back();
    }
  }

  private cancelThumbnailModification() {
    if (this.weet.thumbnail) {
      this.currentThumbnail = getImageURLForSize(this.weet.thumbnail, 1280);
    }
    this.thumbnailModified = false;
  }


  private getSelector(): TimeLineSelector {
    return this.$refs.timeSelector as TimeLineSelector;
  }

  private updateSelector() {
    if (this.getSelector()) {
      this.getSelector().updateSlider();
    }
  }


  private changePlayerTimeAndGenerateThumnbail(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
    this.$nextTick(this.generateThumbnail);
  }

  private beforeDestroy() {
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    store.dispatch(PREVIEW_MODE, true);
    this.tumbnailGenerator.destroy();
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingThumbnailContainer {
  margin: auto;

  .colContainer {
    display: flex;
    width: 100%;

    .colLeft {
      background: var(--light);
      min-width: 226px;
      max-width: 226px;
      text-align: left;
      height: 244px;
      border-radius: 0px 0px 0px 8px;
      animation-duration: 0.2s;
      position: relative;


      .whiteFade {
        position: absolute;
        bottom: -0px;
        left: 0px;
        width: 100%;
        height: 40px;
        pointer-events: none;
        border-radius: 0px 0px 0px 8px;
        background: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1));
      }

      .titlePanelScroll {
        font-size: 16px;
        color: var(--black);
        padding-top: 8px;
        font-weight: 900;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }

      .thumbnailOptionContainer {
        width: 100%;
        height: 100%;
        max-height: 232px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;

        .optionLineTitle {
          margin-top: 8px;
          font-weight: 900;
          color: var(--black);
          font-size: 18px;
        }

        .uploadElement {
          position: relative;
          margin-top: 8px;

          .uploadPreviewElement {
            border: solid 1px var(--light1);
            border-radius: 8px;
          }

          .trashIcon {
            position: absolute;
            top: -12px;
            right: -12px;
          }
        }

        .optionLine {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .labelOption {
            font-size: 14px;
            font-weight: 600;
            color: var(--dark);
          }
        }
      }

    }

    .colRight {
      flex: 1;
      position: relative;
    }

  }

  .thumbnailPreview {
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    bottom: 16px;
    left: 228px;
    margin-top: 12px;
    margin-left: 16px;
    border: solid 1px var(--light);
    border-radius: 8px;
    width: 204px;
    height: calc(204px * 0.56);
    background-size: cover;
    background-position: bottom left;

    .previewHover {
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      display: none;
    }

    &:hover {
      .previewHover {
        display: flex;
      }
    }
  }

  .playerContainer {
    position: relative;
    height: 324px;
    max-height: 324px;
    margin: auto;
    background: var(--black);

    .progressWeet {
      display: flex;
      justify-items: center;
      align-content: center;
      height: 100%;
      width: 100%;
      padding: 64px;
    }
  }


  .backButton {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .timeLineView {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }

  .buttonLine {
    position: absolute;
    display: flex;
    bottom: 32px;
    right: 32px;
    width: calc(100% - 128px);

    .buttonAction {
      margin-left: 16px;
    }

    .spacer {
      flex: 1;
    }
  }


  .buttonBar {
    display: flex;
    margin-top: 40px;
    width: calc(100% - 32px);
    margin-left: 16px;
    align-items: center;

    .buttonSmart {
      margin-right: 8px;
    }

  }

  .buttonCancel {
    position: absolute;
    bottom: 40px;
    left: 236px;
  }

  .buttonElement {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  .thumbnailLargeImage {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      border-radius: 16px;
      @extend .shadow1;
    }
  }
}
</style>
