<template>
  <div class="weetAdvancedEditingChapterContainer">
    <div class="colContainer">
      <Transition name="zoom">
        <div class="colLeft" :class="{modeIntegration:modeIntegration}" v-on:mouseover="deactivateAutoscroll=true" v-on:mouseleave="deactivateAutoscroll=false">
          <perfect-scrollbar class="chapterContainer" ref="scrollPanel">
            <w-button class="buttonAddChapter" size="small" :expanded="true" color="outlined" @click="addChapter(timerPlayer)"
            icon="plus" v-show="getChapterTitles.length>0">
              {{$t('createWeetComponent.advancedEditing.chapterMode.newSection.add')}}
            </w-button>
            <!-- chapter element -->
            <div :ref="'chapterTitleChapterMode'+chapter.time" v-for="chapter in getChapterTitles" class="itemChapter"
                 @click="changePlayerTime(chapter.time)"
                 :key="'chapterModeChapter'+chapter.time"
                 :class="(currentChapterSelected && chapter.time===currentChapterSelected.time)?'selected':''">
              <span class="timeItem">{{ getSimpleTime(chapter.time) }}</span>
              <span contenteditable="true" class="chapterTitleValue" :time="chapter.time"
                    :class="chapter.value===$t('createWeetComponent.advancedEditing.chapterMode.newSection.placeHolder').toString()?'placeHolder':''"
                    @focusout="validateTitleChapter" @keydown.enter="focusOutTitleChapterEdition">
            {{ chapter.value }}
            </span>
              <span class="deleteAnnotation" @click="deleteChapterEvent(chapter)">
                <w-icon size="small" icon="delete-outline" color="dark"/>
              </span>
            </div>
            <Transition name="fade">
              <div class="emptyState" v-show="getChapterTitles.length===0">
                <div class="emptyStateTitle">{{
                    $t('createWeetComponent.advancedEditing.chapterMode.emptyTitle')
                  }}
                </div>
                <div class="emptyStateSubTitle">{{
                    $t('createWeetComponent.advancedEditing.chapterMode.emptySubTitle')
                  }}
                </div>
                <w-button class="buttonAddChapter" size="small" :expanded="true" :color="modeIntegration?'primary':'outlined'" @click="addChapter(timerPlayer)"
                          icon="plus">
                  {{$t('createWeetComponent.advancedEditing.chapterMode.newSection.add')}}
                </w-button>
              </div>
            </Transition>
          </perfect-scrollbar>
          <div class="whiteFade"></div>
        </div>
      </Transition>
      <div class="colRight">
        <TimeLineSelector ref="timeSelector" :weet="weet" class="chapterTimeLineSelector" :class="{modeIntegration:modeIntegration}"
                          @click="addChapter" @delete="deleteChapter" :mode-integration="modeIntegration">
        </TimeLineSelector>
      </div>
    </div>
    <Transition name="fade">
      <w-button class="buttonCancel" size="small" v-show="timeLineModified"
                icon="undo" :enabled="timeLineModified"
                color="text" :class="{modeIntegration:modeIntegration}"
                @click="cancelTimeLineModification()">
        {{ $t('createWeetComponent.advancedEditing.button.cancelEditing') }}
      </w-button>
    </Transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import {REFRESH_WEET_FOR_EDITING} from '@/store/myWeet/myWeetAction';
import {PREVIEW_MODE, TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {ADD_TIME_EVENT, DELETE_TIME_EVENT, SEEK_PLAYER, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import {
  getChapterEventByIndex,
  getDurationOfTimeLine,
  getFreeTimeAfterEvent,
  getTimeEventbeforeTime,
  getTimeEventExactTime
} from '@/utils/timeLineUtils';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {TimeEventType} from '@/enum/TimeEventType';
import prettyMilliseconds from 'pretty-ms';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import {inform} from '@/utils/dialog';
import VueScrollTo from 'vue-scrollto';
import WIcon from "@/components/wrapper/w-icon.vue";
import {clone} from "@/utils/cloneUtil";


@Component({
  components: {
    WIcon,
    ProgressProcessPlayer, TimeLineSelector, TimeLine, EndWeetButton, WButton, WError, WInput, WeetPlayer},
})
export default class CreateWeetChapterMode extends Vue {

  @Prop()
  private weet!: Weet;

  @Prop({default:false})
  private modeIntegration!: boolean;


  private deactivateAutoscroll = false;

  get timeLineModified(): boolean {
    return store.getters.isAdvancedEditingModified;
  }

  get currentChapterSelected(): TimeEvent | undefined {
    return getTimeEventbeforeTime(this.weet.timeLine, this.timerPlayer, TimeEventType.CHAPTER_TITLE);
  }

  get timerPlayer() {
    return store.getters.getTimerPlayer;
  }

  get getChapterTitles(): TimeEvent[] {
    const tes: TimeEvent[] = [];
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.CHAPTER_TITLE) {
        tes.push(te);
      }
    }
    return tes;
  }

  get durationOfTimeLine() {
    return getDurationOfTimeLine(this.weet.timeLine);
  }

  private mounted() {
    setPageEvent(PageEvent.page_record_step_advanced_chapter);
  }

  private getSimpleTime(time: number) {
    return prettyMilliseconds(time, {colonNotation: true, secondsDecimalDigits: 0});
  }

  private createChapter(){

  }
  @Watch('timerPlayer')
  private scrollToChapter() {
    if (this.deactivateAutoscroll || (!this.$refs.scrollPanel && this.getChapterTitles.length > 1)) {

      return;
    }

    // @ts-ignore
    const panel = this.$refs.scrollPanel.$el as HTMLDivElement;
    const timeEvent = getTimeEventbeforeTime(this.weet.timeLine, this.timerPlayer, TimeEventType.CHAPTER_TITLE);

    if (!timeEvent) {
      return;
    }
    // @ts-ignore
    const elementToScroll = this.$refs['chapterTitleChapterMode' + timeEvent.time][0] as HTMLDivElement;
    const options = {
      container: panel,
      easing: 'linear',
      lazy: true,
      offset: 0,
      force: false,
      cancelable: true,
      onStart: (element) => {
        // scrolling started
      },
      onDone: (element) => {
        // scrolling is done
      },
      onCancel: () => {
        // scrolling has been interrupted
      },
      x: false,
      y: true
    };
    VueScrollTo.scrollTo(elementToScroll, 180, options);
  }

  private focusOutTitleChapterEdition(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

  private validateTitleChapter(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      let value = element.innerText.trim();
      if (value === '') {
        value = this.$t('createWeetComponent.advancedEditing.chapterMode.newSection.placeHolder').toString();
        element.innerText = value;
      }
      if (value.length > 150) {
        value = value.substr(0, 149);
        element.innerText = value;
      }
      const time = parseInt(element.getAttribute('time') + '', 10);

      const timeEvent = getTimeEventExactTime(this.weet.timeLine, time);

      if (timeEvent) {
        if (timeEvent.value !== value) {
          // so now we clone and add the timeEvent
          const newTe = clone(timeEvent);
          newTe.value = value;
          store.dispatch(ADD_TIME_EVENT, newTe);
          store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
          inform(this.$t('createWeetComponent.advancedEditing.chapterMode.titleModificationSuccess').toString());
        }
      }
    }
  }

  private async addChapter(time: number) {
    const timeEventChapter = clone(getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY));
    timeEventChapter.time = getFreeTimeAfterEvent(this.weet.timeLine, time);
    timeEventChapter.type = TimeEventType.CHAPTER_TITLE;
    timeEventChapter.value = this.$t('createWeetComponent.advancedEditing.chapterMode.newSection.placeHolder').toString();
    await store.dispatch(ADD_TIME_EVENT, timeEventChapter);
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
    setActionEvent(ActionEvent.chapter_mode_add_chapter);

    // get currentSection
    this.updateSelector();
  }


  private async deleteChapterEvent(timeEventToDelete:TimeEvent){
    const timeToRewind = this.timerPlayer;
    await store.dispatch(DELETE_TIME_EVENT, timeEventToDelete);
    this.updateSelector();
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
    setTimeout(() => {
      this.changePlayerTime(timeToRewind);
    }, 100);
  }
  private async deleteChapter(sectionNumber: number) {
    const timeToRewind = this.timerPlayer;
    const timeEventToDelete = getChapterEventByIndex(this.weet.timeLine, sectionNumber);
    await store.dispatch(DELETE_TIME_EVENT, timeEventToDelete);
    this.updateSelector();
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
    setTimeout(() => {
      this.changePlayerTime(timeToRewind);
    }, 100);

    // amplitude
    setActionEvent(ActionEvent.chapter_mode_remove_chapter);
  }


  private getSelector(): TimeLineSelector {
    return this.$refs.timeSelector as TimeLineSelector;
  }

  private updateSelector() {
    if (this.getSelector()) {
      this.getSelector().updateSlider();
    }
  }


  private async cancelTimeLineModification() {
    await store.dispatch(REFRESH_WEET_FOR_EDITING, this.weet.weetID);
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    this.updateSelector();
    setTimeout(() => {
      this.changePlayerTime(100);
    });
  }

  private changePlayerTime(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
  }

  private beforeDestroy() {
    this.cancelTimeLineModification();
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    store.dispatch(PREVIEW_MODE, true);
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingChapterContainer {
  margin: auto;

  .colContainer {
    display: flex;
    width: 100%;

    .colLeft {
      background: var(--light);
      min-width: 226px;
      max-width: 226px;
      text-align: left;
      height: 244px;
      border-radius: 0px 0px 0px 8px;
      animation-duration: 0.2s;
      //position: relative;
      &.modeIntegration{
        border-radius: 8px
      }

      .whiteFade {
        position: absolute;
        bottom: -0px;
        left: 0px;
        width: 100%;
        height: 40px;
        pointer-events: none;
        border-radius: 0px 0px 0px 8px;
        background: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1));
      }

      .titlePanelScroll {
        font-size: 16px;
        color: var(--black);
        padding-top: 8px;
        font-weight: 900;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }

      .chapterContainer {
        width: 100%;
        height: 100%;
        max-height: 232px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;

        .buttonAddChapter{
          margin-top:8px;
        }
        .itemChapter {
          max-width: 600px;
          width: 100%;
          margin-top: 8px;
          min-height: 32px;
          white-space: normal;
          background: var(--light1);
          display: flex;
          border-radius: 8px;
          padding-left: 8px;
          padding-right: 8px;
          color: var(--dark);
          align-items: flex-start;
          font-size: 16px;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          overflow: hidden;
          border: 1px solid var(--light1);

          .timeItem {
            color: var(--dark);
            margin-right: 4px;
            font-weight: 900;
            font-size: 16px;
            margin-top: 4px;
          }

          .deleteAnnotation {
            cursor: pointer;
            margin-top: 4px;
            &:hover {
              transform: scale(1.2);
            }
          }

          &.selected {
            border: 1px solid var(--dark);
            .timeItem {
              color: var(--dark);
            }

            .chapterTitleValue {
              font-weight: 700;
            }
          }

          .chapterTitleValue {
            flex: 1;
            margin-top: 4px;
            color: var(--dark);
            &.placeHolder {
              color: var(--light2);
            }
          }


          &:hover {
            border: 1px solid var(--light2);
          }
        }
      }

      .emptyState {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .buttonAddChapter{
          margin-top: 16px;
        }
        .emptyStateTitle {
          font-size: 16px;
          color: var(--light2);
          font-weight: 900;
          letter-spacing: 0.3px;
        }

        .emptyStateSubTitle {
          margin-top: 8px;
          font-size: 16px;
          color: var(--light2);
          text-align: center;
        }
      }
    }

    .colRight {
      flex: 1;
      position: relative;
    }
  }


  .playerContainer {
    position: relative;
    height: 324px;
    max-height: 324px;
    margin: auto;
    background: var(--black);

    .progressWeet {
      display: flex;
      justify-items: center;
      align-content: center;
      height: 100%;
      width: 100%;
      padding: 64px;
    }
  }


  .backButton {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .timeLineView {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }

  .buttonLine {
    position: absolute;
    display: flex;
    bottom: 32px;
    right: 32px;
    width: calc(100% - 128px);

    .buttonAction {
      margin-left: 16px;
    }

    .spacer {
      flex: 1;
    }
  }

  .titleToolMode {
    font-size: 24px;
    font-weight: 900;
    flex-grow: 1;
    color: var(--black);
    text-align: left;
    justify-content: center;
    line-height: 32px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .buttonBar {
    display: flex;
    margin-top: 40px;
    width: calc(100% - 32px);
    margin-left: 16px;
    align-items: center;

    .buttonSmart {
      margin-right: 8px;
    }

  }

  .buttonCancel {
    position: absolute;
    bottom: 24px;
    left: 236px;
    .modeIntegration{

    }
  }

  .chapterTimeLineSelector{
    &.modeIntegration{
      margin-top: -8px;
      margin-left: 16px;
    }
  }

}
</style>
