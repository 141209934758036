<template>
  <div>
    <div class="emailInput">
      <b-taginput
          ref="shareContactInput"
          v-model="listContactToShare"
          :data="contactsFiltered"
          field="value"
          type="is-light"
          :keep-first="true"
          :autocomplete="true"
          :ellipsis="true"
          :allow-new="false"
          :allow-duplicates="false"
          :rounded="true"
          :size="'is-'+size"
          :maxlength="100"
          :max-height="maxHeight"
          :open-on-focus="!!workspaceSelectedID && listContactToShare.length===0 && channel"
          :confirmKeys="[',', 'Tab', 'Enter',';']"
          :on-paste-separators="[',',' ',';','Tab']"
          :placeholder="getPlaceHolder"
          @typing="onInputChange"
          :before-adding="beforeAddingEmail"
          :maxtags="maxtags"
          @blur="beforeAddingEmail"
          :readonly="!hasInput">
        <template v-slot="props">
          <ShareContactSuggestion :contactOrGroupOrUser="props.option"/>
        </template>
      </b-taginput>
      <w-error :message="error"/>
    </div>
  </div>
</template>
<style lang="scss">
.emailInput {
  .tag {
    .has-ellipsis {
      max-width: 15em;
    }
  }
}
</style>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import WError from '@/components/wrapper/w-error.vue';
import { isEmail} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WInput from '@/components/wrapper/w-input.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import uniqWith from 'lodash/uniqWith';
import {RESET_CONTACT_AND_GROUP_SEARCHED, SEARCH_CONTACT, SEARCH_GROUP} from '@/store/contact/contactAction';
import WIcon from '@/components/wrapper/w-icon.vue';
import ShareContactSuggestion from '@/components/share/ShareContactSuggestion.vue';
import {WORKSPACE_CHANNEL_FILTER, WORKSPACE_EMAIL_FILTER} from '@/utils/workspaceUtil';
import {SEARCH_WORKSPACE_USERS_BY_EMAIL} from '@/store/workspace/workspaceAction';
import {User} from '@/store/user/userModel';
import {GroupContact} from '@/store/contact/contactModel';
import {Channel} from '@/store/channel/channelModel';
import {clone} from "@/utils/cloneUtil";

@Component({
  components: {WToolTip, WInput, UserAvatar, WError, PopUpTitle, WModal, WButton, WIcon, ShareContactSuggestion}
})
export default class ShareContactInput extends Vue {
  private mustShow: boolean = false;

  @Prop({default: 'default'})
  private size!: string;

  @Prop({default: true})
  private externalUser!: boolean;

  @Prop({default: true})
  private internalUser!: boolean;

  @Prop({default: true})
  private channel!: boolean;

  @Prop({default: ''})
  private placeHolder!: string;

  @Prop({default: 250})
  private maxHeight!: number;
  @Prop({default: null})
  private maxtags!: number | null;

  private error: string = '';
  private loading: boolean = false;
  private listContactToShare: string[] = [];
  private currentInput: string = '';


  private debounceEmailsData = debounce((email) => {
    this.currentInput = email;
    this.loadFilteredEmail(email);
  }, 250);

  @Prop({default: () => []})
  private initialListContactToShare!: string[];


  private onInputChange(email) {
    this.currentInput = email;
    this.debounceEmailsData(email);
  }

  get getPlaceHolder(): string {
    return this.hasInput ? this.placeHolder || this.$t('shareContactInput.emailPlaceholder').toString() : '';
  }

  get workspaceSelectedID(): string {
    return store.getters.getSelectedWorkspaceID;
  }

  private mounted() {
    this.mustShow = true;
    this.loading = false;
    this.listContactToShare = clone(this.initialListContactToShare);

  }


  // Get email from contacts we already shared a weet
  public loadFilteredEmail(emailRegex: string) {
    if (store.getters.isAuthenticated && !emailRegex.startsWith(WORKSPACE_CHANNEL_FILTER)) {
      const isWorkspaceOnlySearch = emailRegex.startsWith(WORKSPACE_EMAIL_FILTER);
      let emailRegexWithoutAt = emailRegex;
      if (emailRegex.startsWith(WORKSPACE_EMAIL_FILTER)) {
        emailRegexWithoutAt = emailRegex.substring(1);
      }
      if (this.workspaceSelectedID && this.internalUser) {
        store.dispatch(SEARCH_WORKSPACE_USERS_BY_EMAIL, emailRegexWithoutAt);
      }
      if (!isWorkspaceOnlySearch && this.externalUser) {
        store.dispatch(SEARCH_CONTACT, {emailRegex: emailRegexWithoutAt});
        store.dispatch(SEARCH_GROUP, {emailRegex: emailRegexWithoutAt});
      } else {
        this.reinitContactsAndGroupsAndWorkspace();
      }
    }
  }


  get filterChannel(): Channel[] {
    const listOfChannel = store.getters.getAllChannels;
    const listFilter = listOfChannel.filter((channel: Channel) => {
      let value = this.currentInput;
      if (value.startsWith(WORKSPACE_CHANNEL_FILTER)) {
        value = this.currentInput.replace(WORKSPACE_CHANNEL_FILTER, '');
      }

      if (value) {
        return channel.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return true;
      }
    });
    return listFilter;
  }

  get contactsFiltered(): [] {
    let data: any[] = [];

    if (this.currentInput.startsWith(WORKSPACE_CHANNEL_FILTER) && this.channel) {
      return this.filterChannel.concat(data) as [];
    }
    if (this.currentInput.startsWith(WORKSPACE_EMAIL_FILTER) && this.internalUser) {
      return store.getters.getUsersSearched as [];
    }

    // workspace user
    if (this.internalUser) {
      data = data.concat(store.getters.getUsersSearched);
    }
    // and contact search
    data = data.concat(store.getters.getContactSearched);


    // filter same user betweet contact and workspace
    data = uniqWith(data, (u1, u2) => {
      let email1 = u1.contactEmail;
      if (u1.contactUser) {
        email1 = u1.contactUser.contactEmail;
      }
      let email2 = u2.contactEmail;
      if (u2.contactUser) {
        email2 = u2.contactUser.contactEmail;
      }
      if (email1 === email2) {
        return true;
      } else {
        return false;
      }
    });

    if (this.channel) {
      data = this.filterChannel.concat(data);
    }

    // and add group
    data = data.concat(
        store.getters.getGroupSearched?.filter((groupContact: GroupContact) => groupContact.members.length > 1)
    );
    // we don't use the field value
    // but we set a value ti remove component error
    for (const el of data) {
      el.value = 'loading';
    }

    if (data.length === 0) {
      // so we add the current text
      const obj = {value: this.currentInput};
      data.push(obj);
    }
    return data as [];
  }

  private reinitContactsAndGroupsAndWorkspace() {
    store.dispatch(RESET_CONTACT_AND_GROUP_SEARCHED);
  }


  private beforeAddingEmail(element) {
    if (element.contactUserID) {
      this.listContactToShare.push(element.contactUser.contactEmail);
    } else if (element.groupID) {
      for (const member of element.members) {
        this.listContactToShare.push(member.contactEmail);
      }
    } else if (element.userID) {
      this.listContactToShare.push((element as User).contactEmail);
    } else if (element.channelID) {
      this.listContactToShare.push('#' + element.name);
    } else if (element.value) {
      this.listContactToShare.push(element.value);
    } else if (element.target.value) { // check for focusOut
      const valueEmail = element.target.value;
      if (isEmail(valueEmail)) {
        this.listContactToShare.push(element.target.value);
        element.target.value = '';
        // after all process we erase the name (it's a hack)
        // please avoid this...
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.shareContactInput.newTag = '';
        });
      }
    }
    // now filter duplicate, and filter email
    this.listContactToShare = this.listContactToShare.filter((value) => {
      return isEmail(value) || value.startsWith(WORKSPACE_CHANNEL_FILTER);
    });
    this.listContactToShare = uniq(this.listContactToShare);
    // we manage the email manually
    return false;

  }

  get hasInput(): boolean {
    return this.maxtags === null || this.listContactToShare.length < this.maxtags;
  }


  @Watch('listContactToShare')
  private commitEmail() {
    this.$emit('userCommit', this.listContactToShare);
  }

  public reset() {
    this.listContactToShare = [];
  }

}
</script>
