<template>
  <div class="weetAdvancedEditingSubtitleContainer">
    <GoToSubtitleWeetButton  v-if="isSubtitleFeatureAvailable" class="iconButton" icon="subtitles-outline" ref="subtitleOpener"
                             :tooltip="$t('createWeetComponent.advancedEditing.tooltip.subtitles')"
                             color="primary"
                             :weet="weet">
      {{ $t('createWeetComponent.advancedEditing.button.subtitleClickMandatory') }}
    </GoToSubtitleWeetButton>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Weet} from '@/store/weet/weetModel';
import GoToSubtitleWeetButton from "@/components/actionButton/GoToSubtitleWeetButton.vue";


@Component({
  components: {
    GoToSubtitleWeetButton
  },
})
export default class CreateWeetSubtitleMode extends Vue {

  @Prop()
  private weet!: Weet;

  get isSubtitleFeatureAvailable(): boolean{
    return store.getters.isTranscript
  }

  get isSubtitleMode(): boolean {
    return store.getters.isSubtitleMode;
  }


}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingSubtitleContainer {
  margin: auto;
  display: flex;
  min-height: 200px;
  justify-content: center;

  align-items: center;
}
</style>
