import {
    ADD_TIME_EVENT, ADD_TIME_LINE,
    ADD_TIMEOFFSET_COMMENT,
    CLEAR_SUBTITLE,
    CLEAR_TIME_LINE, DELETE_TIME_EVENT,
    DETTACHED_OBSOLETE_MEDIA,
    MAIN_MEDIA_BUFFERING,
    MediaIDUpdateParams,
    MOVE_SECTION_EVENT,
    PLAYER_PLAYING,
    PLAYER_SUBTITLE, PLAYER_TIMELINE_CURRENT_EVENT,
    PLAYER_TIMELINE_NEXT_EVENT, PLAYER_TITLE,
    REMOVE_LAST_SECTION_EVENT,
    REMOVE_SECTION_EVENT,
    SAVE_TIMELINE_IF_NEED,
    SECOND_MEDIA_BUFFERING, SEEK_PLAYER,
    SET_NEW_TIMELINE,
    SET_TIMER_RECORD_TO_END,
    SWITCH_MINIMISE_STIKERS,
    SWITCH_PLAYER_FULLSCREEN,
    TIMELINE_SAVED, TIMELINE_UNSAVED,
    TIMELINE_UPDATE_MEDIAID,
    TIMER_PLAYER,
    TIMER_RECORD, UPDATE_TIME_TIME_EVENT,
} from '@/store/timeLine/timeLineAction';
import {OffsetTimeEventUpdate, TimeEvent, TimeEventMedia} from '@/store/timeLine/timeEvent';
import Vue from 'vue';
import {TimeEventType} from '@/enum/TimeEventType';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {sendMessageToExtention} from '@/utils/extentionUtil';
import {log} from '@/utils/log';
import {
    ATTACHED_TIMELINE_TO_WEET,
    AttachedTimeLineParams,
    DETACHED_MEDIA_TO_WEET,
    DettachedMediaParams
} from '@/store/myWeet/myWeetAction';
import {DELETE_MEDIA, REMOVE_MEDIA_FROM_QUEUE} from '@/store/media/mediaAction';
import {
    deleteASection,
    getDurationOfSection,
    getDurationOfTimeLine,
    getFinalPositionOfSection,
    getObsoleteMedia, getOrdonedTimeLineFromState,
    moveASection,
    timeOfLastSection
} from '@/utils/timeLineUtils';
import {clone} from "@/utils/cloneUtil";

// This is the timeLine For The preview
export default {
    state: {
        // record time
        timerRecord: -1,
        recordTimeEvents: {},
        // use to store the manipulation of the timeline and offset the comment timestamp
        offsetComment: [],
        // Global playerTimer
        timerPlayer: 0,
        seekTimerPlayer: -1,
        playing: false,
        fullscreenMode: false,
        minimizeStickers: false,
        mainMediaBuffering: 0,
        secondMediaBuffering: 0,
        currentTimeEvent: undefined,
        // for preloading
        nextTimeEvent: undefined,
        playerTitle: undefined,
        timeLineSaved: true,
        subtitlePlayer: '',
    },
    mutations: {

        [TIMER_RECORD]: (state: any, timer: number) => {
            state.timerRecord = timer;
        },
        [PLAYER_PLAYING]: (state: any, play: boolean) => {
            state.playing = play;
        },
        [PLAYER_SUBTITLE]: (state: any, vtt: string) => {
            state.subtitlePlayer = vtt;
        },
        [CLEAR_SUBTITLE]: (state: any) => {
            state.subtitlePlayer = '';
        },
        [SWITCH_PLAYER_FULLSCREEN]: (state: any, fullscreen: boolean) => {
            state.fullscreenMode = fullscreen;
        },
        [SWITCH_MINIMISE_STIKERS]: (state: any, minimise: boolean) => {
            state.minimizeStickers = minimise;
        },
        [MAIN_MEDIA_BUFFERING]: (state: any, timerBuffering: number) => {
            state.mainMediaBuffering = timerBuffering;
        },
        [SECOND_MEDIA_BUFFERING]: (state: any, timerBuffering: number) => {
            state.secondMediaBuffering = timerBuffering;
        },
        [TIMER_PLAYER]: (state: any, timer: number) => {
            state.timerPlayer = timer;
        },
        [SEEK_PLAYER]: (state: any, timer: number) => {
            state.seekTimerPlayer = timer;
        },
        [CLEAR_TIME_LINE]: (state: any) => {
            state.recordTimeEvents = {};
            state.timerRecord = 0;
            state.mediaControlType = TimeEventType.NONE;
        },
        [TIMELINE_SAVED]: (state: any, value: boolean) => {
            state.timeLineSaved = value;
            if (value) {
                state.offsetComment = [];
            }
        },
        [ADD_TIME_EVENT]: (state: any, timeEvent: TimeEvent) => {
            const arrayTimeEventForTime = [timeEvent];
            // remove other MEDIA_PAUSE (only one on the timeLine)
            if (timeEvent.type === TimeEventType.MEDIA_PAUSE) {
                for (const timeEArray of Object.values(state.recordTimeEvents)) {
                    const tabOfTE = timeEArray as TimeEvent[];
                    for (const te of tabOfTE) {
                        if (te.type === TimeEventType.MEDIA_PAUSE) {
                            // remove this event from the timeLine
                            Vue.delete(state.recordTimeEvents, te.time);
                        }
                    }
                }
            }
            Vue.set(state.recordTimeEvents, timeEvent.time, arrayTimeEventForTime);
        },
        [ADD_TIME_LINE]: (state: any, timeEvents: TimeEvent[]) => {
            state.recordTimeEvents = {};
            for (const timeE of timeEvents) {
                const tabOfTE = [timeE];
                Vue.set(state.recordTimeEvents, timeE.time, tabOfTE);
            }
        },
        [DELETE_TIME_EVENT]: (state: any, timeEvent: TimeEvent) => {
            Vue.delete(state.recordTimeEvents, timeEvent.time);
        },
        [ADD_TIMEOFFSET_COMMENT]: (state: any, offsetTimeEventUpdate: OffsetTimeEventUpdate) => {
            state.offsetComment.push(offsetTimeEventUpdate);
        },
        [PLAYER_TIMELINE_CURRENT_EVENT]: (state: any, timeEvent: TimeEvent) => {
            state.currentTimeEvent = timeEvent;
        },

        [PLAYER_TIMELINE_NEXT_EVENT]: (state: any, timeEvent: TimeEvent) => {
            state.nextTimeEvent = timeEvent;
        },
        [PLAYER_TITLE]: (state: any, value: string) => {
            state.playerTitle = value;
        },
        // TODO FIND a way to do better than this ugly code
        [TIMELINE_UPDATE_MEDIAID]: (state: any, params: MediaIDUpdateParams) => {
            // Change main Media
            if (state.mainMedia !== undefined
                && state.mainMedia.mediaID === params.oldID) {
                state.mainMedia.mediaID = params.media.mediaID;
            }
            // Change Second Media
            if (state.secondMedia !== undefined
                && state.secondMedia.mediaID === params.oldID) {
                state.secondMedia.mediaID = params.media.mediaID;
            }

            // Now we change in all timeline
            for (const key of Object.keys(state.recordTimeEvents)) {
                // Change main Media
                const teArray = state.recordTimeEvents[key] as TimeEvent[];
                for (const te of teArray) {
                    if (te.mainMedia.mediaID === params.oldID) {
                        te.mainMedia.mediaID = params.media.mediaID;
                    }
                    // Change Second Media
                    if (te.secondMedia.mediaID === params.oldID) {
                        te.secondMedia.mediaID = params.media.mediaID;
                    }
                }
            }
        },
    },
    getters: {
        // is weet player currently playing
        isPlaying: (state: any): boolean => {
            return state.playing;
        },
        getSubtitle: (state: any): string => {
            return state.subtitlePlayer;
        },
        timelineNeedToBeSaved: (state: any): boolean => {
            return !state.timeLineSaved;
        },
        // get the time of record
        getTimerRecord: (state: any): number => {
            return state.timerRecord;
        },
        // get the time of player
        getTimerPlayer: (state: any): number => {
            return state.timerPlayer;
        },
        getSeekTimerPlayer: (state: any): number => {
            return state.seekTimerPlayer;
        },
        isFullscreenMode: (state: any): boolean => {
            return state.fullscreenMode;
        },
        isMinimizeSticker: (state: any): boolean => {
            return state.minimizeStickers;
        },
        // return the time of main video is buffering
        getMainVideoBuffering: (state: any): number => {
            return state.mainMediaBuffering;
        },
        // return the time of second video buffering (stickers)
        getSecondVideoBuffering: (state: any): number => {
            return state.secondMediaBuffering;
        },
        getPlayerTitle: (state: any): string => {
            return state.playerTitle;
        },
        getPlayerMainMedia: (state: any): TimeEventMedia | undefined => {
            if (state.currentTimeEvent) {
                return state.currentTimeEvent.mainMedia;
            }
            return undefined;
        },
        getPlayerSecondMedia: (state: any): TimeEventMedia | undefined => {
            if (state.currentTimeEvent) {
                return state.currentTimeEvent.secondMedia;
            }
            return undefined;
        },
        getCurrentPlayerTimeEvent: (state: any): TimeEvent | undefined => {
            return state.currentTimeEvent;
        },
        getNextPlayerTimeEvent: (state: any): TimeEvent | undefined => {
            return state.nextTimeEvent;
        },
        getTimeEvent: (state: any): TimeEvent[] => {
            return getOrdonedTimeLineFromState(state.recordTimeEvents);
        },
        getTimeLineDuration: (state: any, getters: any): number => {
            return getDurationOfTimeLine(getters.getTimeEvent);
        },
        getOffsetTimeEventUpdate: (state: any, getters: any): OffsetTimeEventUpdate[] => {
            return state.offsetComment;
        },
    },
    actions: {
        [TIMER_RECORD]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }, timer: number) {
            if (!getters.isWithoutExtentionRecord) {
                // on time by second
                // the step is 250ms (store that in const)
                // Chrome don't like overflood of message
                if (timer % 1000 <= 250) {
                    // compute count down timer
                    sendMessageToExtention(TIMER_RECORD, timer);
                }
            }
            commit(TIMER_RECORD, timer);
        },
        [TIMER_PLAYER]({commit, dispatch}: { commit: any, dispatch: any }, timer: number) {
            commit(TIMER_PLAYER, timer);
        },
        [SEEK_PLAYER]({commit, dispatch}: { commit: any, dispatch: any }, timer: number) {
            commit(SEEK_PLAYER, timer);
        },
        [PLAYER_PLAYING]({commit, dispatch}: { commit: any, dispatch: any }, play: boolean) {
            commit(PLAYER_PLAYING, play);
        },
        [PLAYER_SUBTITLE]({commit, dispatch}: { commit: any, dispatch: any }, vtt: string) {
            commit(PLAYER_SUBTITLE, vtt);
        },
        [CLEAR_SUBTITLE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(CLEAR_SUBTITLE);
        },
        [SWITCH_PLAYER_FULLSCREEN]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(SWITCH_PLAYER_FULLSCREEN, value);
        },
        [SWITCH_MINIMISE_STIKERS]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(SWITCH_MINIMISE_STIKERS, value);
        },
        [ADD_TIME_EVENT]({
                             commit,
                             dispatch,
                             getters
                         }: { commit: any, dispatch: any, getters: any }, timeEvent: TimeEvent) {
            commit(ADD_TIME_EVENT, timeEvent);
            // we inform the timeline need to be saved
            if (getters.getRecordingState === RecordingState.RECORDING) {
                commit(TIMELINE_SAVED, false);
            }
        },
        [DELETE_TIME_EVENT]({
                                commit,
                                dispatch,
                                getters
                            }: { commit: any, dispatch: any, getters: any },
                            timeEvent: TimeEvent) {
            commit(DELETE_TIME_EVENT, timeEvent);
            // we inform the timeline need to be saved
            if (getters.getRecordingState === RecordingState.RECORDING) {
                commit(TIMELINE_SAVED, false);
            }
        },
        [UPDATE_TIME_TIME_EVENT]({
                                     commit,
                                     dispatch,
                                     getters
                                 }: { commit: any, dispatch: any, getters: any },
                                 {timeEvent, time}: { timeEvent: TimeEvent, time: number }) {

            if (timeEvent && time !== timeEvent.time) {
                commit(DELETE_TIME_EVENT, timeEvent);
                timeEvent.time = time;
                commit(ADD_TIME_EVENT, timeEvent);
            }
        },
        async [MOVE_SECTION_EVENT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            {startSection, moveTo}: { startSection: number, moveTo: number }) {

            log.info('Move the section from weet to time ' + startSection + ' ' + moveTo);

            const offset = new OffsetTimeEventUpdate();
            offset.start = startSection;
            offset.to = getFinalPositionOfSection(getters.getTimeEvent, startSection, moveTo);
            offset.duration = getDurationOfSection(getters.getTimeEvent, startSection);
            const timeLines = moveASection(clone(getters.getTimeEvent), startSection, moveTo);

            commit(ADD_TIMEOFFSET_COMMENT, offset);
            //
            dispatch(SET_NEW_TIMELINE, timeLines);
            //
            dispatch(SET_TIMER_RECORD_TO_END);
            //
            // // we save the timeline now if all media is saved
            await dispatch(SAVE_TIMELINE_IF_NEED);
        },
        async [REMOVE_LAST_SECTION_EVENT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {

            log.info('Remove the Last section from speach');

            const time = timeOfLastSection(getters.getTimeEvent);
            return await dispatch(REMOVE_SECTION_EVENT, time);
        },
        async [REMOVE_SECTION_EVENT](
            {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
            timeToDelete: number) {

            log.info('Remove the section from weet to time ' + timeToDelete);

            const timeLines = deleteASection(clone(getters.getTimeEvent), timeToDelete);

            dispatch(SET_NEW_TIMELINE, timeLines);

            dispatch(SET_TIMER_RECORD_TO_END);

            await dispatch(DETTACHED_OBSOLETE_MEDIA);
            // we save the timeline now if all media is saved
            await dispatch(SAVE_TIMELINE_IF_NEED);
        },
        [SET_NEW_TIMELINE]({
                               commit,
                               dispatch,
                               getters
                           }: { commit: any, dispatch: any, getters: any },
                           timeLines: TimeEvent[]) {
            commit(CLEAR_TIME_LINE);
            for (const timeEvent of timeLines) {
                commit(ADD_TIME_EVENT, timeEvent);
            }
            commit(TIMELINE_SAVED, false);
        },
        /**
         * Setup the record timer to the end of the timeline
         * @param commit
         * @param dispatch
         * @param getters
         */
        [SET_TIMER_RECORD_TO_END]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            let newTimeEndTimeLine = 0;
            for (const timeEvent of getters.getTimeEvent) {
                if (timeEvent.type === TimeEventType.MEDIA_PAUSE) {
                    newTimeEndTimeLine = timeEvent.time;
                }
            }
            // reset the time of record
            log.debug('time to record', newTimeEndTimeLine);
            dispatch(TIMER_RECORD, newTimeEndTimeLine);
        },
        /**
         * DETACHED unused Media, and Cancel upload if need
         * @param commit
         * @param dispatch
         * @param getters
         */
        async [DETTACHED_OBSOLETE_MEDIA]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            const obsoleteMedia = getObsoleteMedia(getters.getTimeEvent, getters.getMedias);
            const weetID = getters.getEditingWeetID;
            log.debug('Check obsolete media');
            for (const media of obsoleteMedia) {
                if (media && media.saved) {
                    // new dettached media or delete if need to the back server
                    const paramsDettached = new DettachedMediaParams(weetID, media.mediaID);
                    await dispatch(DETACHED_MEDIA_TO_WEET, paramsDettached);
                    log.debug('Detached obsolete media already uploaded ' + media.mediaID);
                } else {
                    // the media is not already saved so we just stop the upload
                    dispatch(REMOVE_MEDIA_FROM_QUEUE, media.mediaID);
                    log.debug('Detached obsolete media not finish uploaded ' + media.mediaID);
                }
                // delete media from the list of media
                dispatch(DELETE_MEDIA, media.mediaID);
            }
        },
        /**
         * Save the timeline of the editing weet if possible
         * Media uploaded
         * @param commit
         * @param dispatch
         * @param getters
         */
        async [SAVE_TIMELINE_IF_NEED]({commit, dispatch, getters}: { commit: any, dispatch: any, getters: any }) {
            const weetID = getters.getEditingWeetID;
            log.debug('is timeline need to be saved ' + getters.timelineNeedToBeSaved + " " + getters.isAllMediaSavedAndValidate);
            if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
                log.debug('Attached new timeline to weet ' + weetID);
                const timeLineAttachedParams = new AttachedTimeLineParams(weetID, getters.getTimeEvent, getters.getOffsetTimeEventUpdate);
                await dispatch(ATTACHED_TIMELINE_TO_WEET, timeLineAttachedParams);
            }
        },
        [TIMELINE_SAVED]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(TIMELINE_SAVED, true);
        },
        [TIMELINE_UNSAVED]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(TIMELINE_SAVED, false);
        },
        [PLAYER_TIMELINE_CURRENT_EVENT]({commit, dispatch}: { commit: any, dispatch: any },
                                        timeEvent: TimeEvent) {
            commit(PLAYER_TIMELINE_CURRENT_EVENT, timeEvent);
        },
        [PLAYER_TIMELINE_NEXT_EVENT]({commit, dispatch}: { commit: any, dispatch: any },
                                     timeEvent: TimeEvent) {
            commit(PLAYER_TIMELINE_NEXT_EVENT, timeEvent);
        },
        [PLAYER_TITLE]({commit, dispatch}: { commit: any, dispatch: any },
                       title: string) {
            commit(PLAYER_TITLE, title);
        },
        [TIMELINE_UPDATE_MEDIAID]({commit, dispatch}: { commit: any, dispatch: any },
                                  params: MediaIDUpdateParams) {
            commit(TIMELINE_UPDATE_MEDIAID, params);
        },
        [CLEAR_TIME_LINE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(CLEAR_TIME_LINE);
        },
        [MAIN_MEDIA_BUFFERING]({commit, dispatch}: { commit: any, dispatch: any }, timeValue: number) {
            commit(MAIN_MEDIA_BUFFERING, timeValue);
        },
        [SECOND_MEDIA_BUFFERING]({commit, dispatch}: { commit: any, dispatch: any }, timeValue: number) {
            commit(SECOND_MEDIA_BUFFERING, timeValue);
        },
    },
};
