<template>
  <div>
    <div class="timeLineSelector" :class="{modeIntegration:modeIntegration}">
      <!-- The offset scroll remove the last tooltip that can provoque some undesirable Xscroll -->
      <perfect-scrollbar class="scrollContainer" ref="scrollPanel"
                         :options="{scrollXMarginOffset:0,wheelPropagation:false,suppressScrollY:true}">
        <div class="timeLineImageBackground" :style="'width: calc('+zoomWidth+'% - 32px);'">
        </div>
        <div class="timeLineCursorConteneur" ref="timeLineCursorConteneur"
             :style="'width: calc('+zoomWidth+'% - 32px);'"
             @click="onClickTimeLineCursor">
          <VueDraggableResizable ref="cursorTimeLine" class="dragableCursor" axis="x" :resizable="false"
                                 :draggable="true"
                                 :parent="false" :style="cursorLeft" :x="cursorLeft" :z="1"
                                 class-name-active="dragableCursor-active" :h="100" :w="1"
                                 @dragstop="onDragCursor">
            <div class="timeLineCursor"></div>
          </VueDraggableResizable>
        </div>
        <div class="timeline" :class="{chapter:isChapterMode,cut:isCutMode,thumnbail:isThumbnailMode}"
             :style="'width: calc('+zoomWidth+'% - 32px);'"
             ref="noUiSliderContainer" @click="onTimeLineClick"
             @mousemove="moveMouseCursor" @mouseout="mouseOutCursor"></div>
        <div class="timeLineMouseCursor" ref="cursorMouseTimeLine"></div>
        <div class="tooltipMousePosition" ref="cursorMouseToolTip" v-show="hoverTime">
          {{ hoverTime }}
        </div>
      </perfect-scrollbar>
      <Transition name="fade">
        <div class="timeLineBackground" v-show="mustShowAdvice">
          <w-icon class="iconAdvice" :icon="iconAdvice"/>
          {{ labelTimeLine }}
        </div>
      </Transition>
      <div class="toolPanel">
        <slot></slot>
        <div class="zoomSelector">
          <w-button @click="zoomOutTimeLine" class="buttonZoom" icon="magnify-minus-outline" size="small" color="text"
                    :tooltip="$t('createWeetComponent.advancedEditing.tooltip.zoomOut')" :enabled="zoomWidth>100"/>
          <div class="zoomSelectorTimeLineContainer">
            {{ Math.round(zoomWidth) }}%
          </div>
          <w-button @click="zoomInTimeLine" class="buttonZoom" icon="magnify-plus-outline" size="small" color="text"
                    :tooltip="$t('createWeetComponent.advancedEditing.tooltip.zoomIn')"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import noUiSlider from 'nouislider';
import {
  getChapterEventByIndex,
  getCutEventByIndex,
  getDurationOfTimeLine,
  getFirstMediaPlayEvent,
  getFreeTimeAfterEvent, getFreeTimeBeforeEvent,
  getTimeEventbeforeTime, isChapterEventOnTimeLine, isCutEventOnTimeLine, isEventOnTimeinTimeline,
} from '@/utils/timeLineUtils';
import store from '@/store';
import {TimeEventType} from '@/enum/TimeEventType';
import {Weet} from '@/store/weet/weetModel';
import {
  ADD_TIME_EVENT,
  DELETE_TIME_EVENT,
  SEEK_PLAYER,
  TIMER_PLAYER,
  UPDATE_TIME_TIME_EVENT
} from '@/store/timeLine/timeLineAction';
import prettyMilliseconds from 'pretty-ms';
import WButton from '@/components/wrapper/w-button.vue';
import TimeLineZoomSelector from '@/components/recorder/timeLine/TimeLineZoomSelector.vue';
import VueScrollTo from 'vue-scrollto';
import WIcon from '@/components/wrapper/w-icon.vue';
import {ActionEvent} from '@/enum/TrackerEnum';
import {setActionEvent} from '@/utils/tracker';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import VueDraggableResizable from 'vue-draggable-resizable';
import {clone} from "@/utils/cloneUtil";

@Component({
  components: {WIcon, TimeLineZoomSelector, WButton, VueDraggableResizable},
})
export default class TimeLineSelector extends Vue {

  @Prop({})
  private weet!: Weet;
  @Prop({default: false})
  private disabled!: false; // implement
  @Prop({default:false})
  private modeIntegration!:boolean;

  private range: { min: number, max: number } = {min: 0, max: 100};
  private initialised = false;
  private magicLoading = false;

  public zoom = [-1, 1];

  private scrollInprogress = false;
  private cancelScroll: any = null;
  private blockScroll: boolean = false;
  private timeOutBlockScroll: any = 0;

  private hoverTime: string | null = null;


  get labelTimeLine(): string {
    if (this.isCutMode) {
      return this.$t('createWeetComponent.advancedEditing.cutmode.advice').toString();
    } else if (this.isChapterMode) {
      return this.$t('createWeetComponent.advancedEditing.chapterMode.advice').toString();
    } else if (this.isThumbnailMode) {
      return this.$t('createWeetComponent.advancedEditing.thumbnailMode.advice').toString();
    } else {
      return '';
    }
  }

  get mustShowAdvice(): boolean {
    if (this.isCutMode) {
      return !isCutEventOnTimeLine(this.weet.timeLine);
    } else if (this.isChapterMode) {
      return !isChapterEventOnTimeLine(this.weet.timeLine);
    } else if (this.isThumbnailMode) {
      return true;
    }
    return false;
  }

  get timeLineModified(): boolean {
    return store.getters.isAdvancedEditingModified;
  }

  get play(): boolean {
    return store.getters.isPlaying;
  }

  /**
   * Get the slider component
   * @private
   */
  private slider(): HTMLDivElement {
    return this.$refs.noUiSliderContainer as HTMLDivElement;
  }

  private sendCancel() {
    this.$emit('cancel');
  }


  get getNbImageTimeLine(): number {
    return Math.round(this.zoomWidth / 8);
  }

  private onClickTimeLineCursor(event) {
    const x = event.layerX;
    const time = this.timeForX(x);
    this.blockScroll = true;
    this.changePlayerTime(time);
    setTimeout(() => {
      this.blockScroll = false;
    }, 100);
  }

  private onDragCursor(x, y) {
    const time = this.timeForX(x);
    this.changePlayerTime(time);
  }

  /**
   * When click on the timeline (outside of a selected section)
   * @param event
   * @private
   */
  private onTimeLineClick(event) {
    if (event.target === this.slider()) {
      const x = event.layerX;
      const time = this.timeForX(x);
      this.$emit('click', time);
    }
  }

  private moveMouseCursor(event) {
    const cursor = this.$refs.cursorMouseTimeLine as HTMLDivElement;
    const tooltip = this.$refs.cursorMouseToolTip as HTMLDivElement;

    if ((event.target as HTMLDivElement).classList.contains('noUi-connect')) {
      cursor.style.display = 'none';
      tooltip.style.display = 'none';
    } else {
      cursor.style.display = 'block';
      cursor.style.left = event.layerX + 'px';
      tooltip.style.display = 'block';
      tooltip.style.left = event.layerX + 'px';
      this.hoverTime = prettyMilliseconds(this.timeForX(event.layerX), {colonNotation: true});
    }
  }

  private mouseOutCursor(event) {
    const cursor = this.$refs.cursorMouseTimeLine as HTMLDivElement;
    cursor.style.display = 'none';
    this.hoverTime = null;
  }

  private zoomInTimeLine() {

    const fivePourc = 0.10 * this.durationOfTimeLine;
    const endZoom = this.zoom[1] - fivePourc;
    if (endZoom - this.zoom[0] < 1000) {
      return;
    }
    Vue.set(this.zoom, 1, Math.round(endZoom));

    // amplitude
    setActionEvent(ActionEvent.timeline_selector_increase_zoom);
  }

  private zoomOutTimeLine() {
    // get 10% of timeLine
    const fivePourc = 0.10 * this.durationOfTimeLine;
    let endZoom = this.zoom[1] + fivePourc;
    let startZoom = this.zoom[0];
    if (endZoom > this.durationOfTimeLine) {
      endZoom = this.durationOfTimeLine;
      startZoom = startZoom - fivePourc;
    }
    Vue.set(this.zoom, 1, Math.round(endZoom));
    Vue.set(this.zoom, 0, Math.round(startZoom));

    // amplitude
    setActionEvent(ActionEvent.timeline_selector_decrease_zoom);
  }


  public resetZoom() {
    Vue.set(this.zoom, 1, Math.round(this.range.max));
    Vue.set(this.zoom, 0, Math.round(this.range.min));
  }


  @Watch('timerPlayer')
  private changePositionZoom() {

    // @ts-ignore
    if (!this.$refs.scrollPanel && !this.$refs.cursorTimeLine) {

      return;
    }
    if (this.scrollInprogress || this.blockScroll || this.zoomWidth < 101) {
      return;
    }
    if (this.cancelScroll) {
      this.cancelScroll();
    }

    // @ts-ignore
    const panel = this.$refs.scrollPanel.$el as HTMLDivElement;
    // @ts-ignore
    const cursor = this.$refs.cursorTimeLine.$el as HTMLDivElement;
    const offset = this.xForTime(this.timerPlayer) - 200;
    const options = {
      container: panel,
      easing: 'linear',
      lazy: true,
      offset: offset,
      force: true,
      cancelable: true,
      onStart: (element) => {
        // scrolling started
        this.scrollInprogress = true;
      },
      onDone: (element) => {
        // scrolling is done
        this.scrollInprogress = false;
      },
      onCancel: () => {
        // scrolling has been interrupted
        this.scrollInprogress = false;
      },
      x: true,
      y: false
    };
    // we have to wait the panel is open in fullscreenmode
    // this.$nextTick(() => {
    this.cancelScroll = VueScrollTo.scrollTo(cursor, 180, options);
    // });
  }

  private scrollMoving(value) {
    // TODO
  }


  get zoomWidth(): number {
    if (this.zoom[0] < 0) {
      return 100;
    }
    const delta = this.zoom[1] - this.zoom[0];
    // get duration  of the weet
    const duration = getDurationOfTimeLine(this.weet.timeLine);

    let pourcZoom = duration / delta * 100;
    if (pourcZoom < 101) {
      pourcZoom = 100;
    }
    return pourcZoom;

  }


  private changePlayerTime(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
  }

  /**
   * Compute the time when click on the component
   * @param x
   * @private
   */
  private timeForX(x: number): number {
    return Math.round(x / this.slider().offsetWidth * getDurationOfTimeLine(this.weet.timeLine));
  }

  private xForTime(time: number): number {
    return Math.round(this.slider().offsetWidth / getDurationOfTimeLine(this.weet.timeLine) * time);
  }


  // /**
  // TODO move in CreateWeetCutter
  //  * Add a Section on the timeline
  //  * @param time
  //  * @private
  //  */
  // private addSectionToTimeLine(time: number) {
  //   // get event before this poinr
  //   const timeEventToInsert = clone(getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY));
  //   timeEventToInsert.time = time;
  //   store.dispatch(ADD_TIME_EVENT, timeEventToInsert);
  // }


  private async changeTimeLine(values, handle, unencoded, tap, positions, noSlider) {
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
  }

  private async updateTimeLine(values, handle, unencoded, tap, positions, noSlider) {


    let teManipulate: TimeEvent | undefined;
    if (this.isCutMode) {
      this.blockScroll = true;
      // looking for the handle with the same value
      teManipulate = getCutEventByIndex(this.weet.timeLine, handle);

    }

    if (this.isChapterMode) {
      teManipulate = getChapterEventByIndex(this.weet.timeLine, handle);
    }

    let time = Math.round(unencoded[handle]);
    if (teManipulate?.time !== time) {
      time = Math.round(getFreeTimeAfterEvent(this.weet.timeLine, time));
      if (time > this.durationOfTimeLine) {
        time = Math.round(getFreeTimeBeforeEvent(this.weet.timeLine, time));
      }
      await store.dispatch(UPDATE_TIME_TIME_EVENT, {timeEvent: clone(teManipulate), time: time});
    }

    if (this.initialised) {
      if (handle % 2 === 0) {
        let offsetTime = time - 300;
        if (offsetTime < 0) {
          offsetTime = 0;
        }
        this.changePlayerTime(offsetTime);
      } else {
        this.changePlayerTime(time);
      }
    }
    clearTimeout(this.timeOutBlockScroll);
    this.timeOutBlockScroll = setTimeout(() => {
      this.blockScroll = false;
    }, 200);
  }

  private async deleteSection(event) {
    event.preventDefault();
    if (this.isCutMode) {
      // get dataHandler
      const sectionNumber = (parseInt(event.target.getAttribute('data-handle'), 10) + 1) / 2;
      this.$emit('delete', sectionNumber);
    }
    if (this.isChapterMode) {
      const sectionNumber = parseInt(event.target.getAttribute('data-handle'), 10);
      this.$emit('delete', sectionNumber);
    }
  }

  get timerPlayer() {
    return store.getters.getTimerPlayer;
  }

  get iconAdvice(): string {
    if (this.isCutMode) {
      return 'content-cut';
    } else if (this.isChapterMode) {
      return 'format-title';
    } else if (this.isThumbnailMode) {
      return 'image-edit-outline';
    }
    return '';
  }

  get isCutMode() {
    return store.getters.isCutMode;
  }

  get isChapterMode() {
    return store.getters.isChapterMode;
  }

  get isThumbnailMode() {
    return store.getters.isThumbnailMode;
  }

  get isCutterMode() {
    return store.getters.isCutterMode;
  }

  get isMergeMode() {
    return store.getters.isMergeMode;
  }

  /**
   * Compute the position of the timer
   */
  get cursorLeft(): number {
    const time = store.getters.getTimerPlayer;
    const duration = getDurationOfTimeLine(this.weet.timeLine);
    let pourc = time / duration;
    if (pourc > 1) {
      pourc = 1;
    }
    const timeLineContainer = this.$refs.timeLineCursorConteneur as HTMLDivElement;

    if (timeLineContainer) {
      return timeLineContainer.offsetWidth * pourc;
    } else {
      return 0;
    }
  }

  get durationOfTimeLine() {
    return getDurationOfTimeLine(this.weet.timeLine);
  }


  private mounted() {
    this.computeRange();
  }

  /**
   * Compute the range of the timeline
   * @private
   */
  private computeRange() {
    if (this.weet.timeLine.length <= 1) {
      this.range.min = 0;
      this.range.max = 1;
    } else {
      const min = getFirstMediaPlayEvent(this.weet.timeLine)?.time || 0;
      // we remove the end to not delete the last event (mediaPAUSE)
      const max = getDurationOfTimeLine(this.weet.timeLine) - 1;
      this.range.min = min;
      this.range.max = max;
      this.zoom = [min, max];
    }
    this.updateSlider();
  }

  private initSlider() {
    if (this.isCutMode) {
      this.initSliderCutMode();
    }
    if (this.isChapterMode) {
      this.initSliderChapterMode();
    }
  }


  private initSliderChapterMode() {
    this.initialised = false;
    const options: any = {};
    const start: number[] = [];
    const toolTip: any[] = [];
    const addToolTip = () => {
      toolTip.push({
        to: (value) => {
          if (value < 100) {
            value = 0;
          }
          return prettyMilliseconds(value, {colonNotation: true});
        }, from: (value) => {
          console.log('From value');
        }
      });
    };

    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.CHAPTER_TITLE) {
        start.push(te.time);
        addToolTip();
      }
    }

    // now we implement 30 s pips
    // TODO


    options.range = this.range;
    options.margin = 100; // cut minimum
    options.step = 100;
    // options.behaviour = 'Unconstrained';
    options.tooltips = toolTip;
    options.pips = {
      mode: 'positions',
      density: 2,
      values: [0, 20, 40, 60, 80, 100],
      stepped: true,
      // values: pipsValue,
      format: {
        to: (value) => {
          if (value < 100) {
            value = 0;
          }
          return prettyMilliseconds(value, {colonNotation: true, secondsDecimalDigits: 0});
        }, from: (value) => {
          console.log('From value');
        }
      }

    };
    options.start = start;
    if (start.length > 0) {
      // @ts-ignore
      noUiSlider.create(this.slider(), options);
      // @ts-ignore
      this.slider().noUiSlider.on('update', this.updateTimeLine);
      // @ts-ignore
      this.slider().noUiSlider.on('change', this.changeTimeLine);
    }

    this.$nextTick(() => {
      this.addConnectorClassSelector();
      this.addDeletesButton();
    });
    setTimeout(() => {
      this.initialised = true;
    }, 1000);


  }

  private initSliderCutMode() {
    this.initialised = false;
    const options: any = {};
    // compute the section
    const start: number[] = [];
    const connect: boolean[] = [];
    const toolTip: any[] = [];
    let sectionInProgress = false;
    let beginSection = 0;
    const addToolTip = () => {
      toolTip.push({
        to: (value) => {
          if (value < 100) {
            value = 0;
          }
          return prettyMilliseconds(value, {colonNotation: true});
        }, from: (value) => {
          console.log('From value');
        }
      });
    };
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.START_CUT) {
        sectionInProgress = true;
        beginSection = te.time;
      }
      if (sectionInProgress &&
          (te.type === TimeEventType.END_CUT
              || te.type === TimeEventType.MEDIA_PAUSE)) {
        sectionInProgress = false;
        start.push(beginSection);
        connect.push(false);
        addToolTip();
        start.push(te.time);
        connect.push(true);
        addToolTip();
      }
    }


    connect.push(false);
    options.start = start;
    options.connect = connect;
    options.range = this.range;
    options.step = 1;
    options.margin = 100; // cut minimum
    options.behaviour = 'drag hover-snap';
    options.tooltips = toolTip;
    options.pips = {
      mode: 'positions',
      density: 2,
      values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      stepped: true,
      // values: pipsValue,
      format: {
        to: (value) => {
          if (value < 100) {
            value = 0;
          }
          return prettyMilliseconds(value, {colonNotation: true, secondsDecimalDigits: 0});
        }, from: (value) => {
          console.log('From value');
        }
      }

    };
    // options.keyboardSupport = true; // Default true
    // options.keyboardPageMultiplier = 0.1; // Default 5
    // options.keyboardDefaultStep = 0.1; // Default 10

    if (start.length > 0) {
      // @ts-ignore
      noUiSlider.create(this.slider(), options);
      // @ts-ignore
      this.slider().noUiSlider.on('update', this.updateTimeLine);
    }

    this.$nextTick(() => {
      this.addConnectorClassSelector();
      this.addDeletesButton();
    });
    setTimeout(() => {
      this.initialised = true;
    }, 1000);

  }

  private addConnectorClassSelector() {
    const slider = this.slider();

    const connectors = slider.querySelectorAll('.noUi-origin');
    if (this.isCutMode) {
      let left = true;
      for (const connect of connectors) {
        if (left) {
          connect.className += ' left';
        } else {
          connect.className += ' right';
        }
        left = !left;
      }
    }
    if (this.isChapterMode) {
      for (const connect of connectors) {
        connect.className += ' chapter';
      }
    }
  }

  private addDeletesButton() {
    // get slider
    const slider = this.slider();

    let connectors = slider.querySelectorAll('.noUi-origin.right');
    if (this.isChapterMode) {
      connectors = slider.querySelectorAll('.noUi-origin.chapter');
    }
    const addButton = (connect: HTMLDivElement) => {
      const div = document.createElement('div');
      div.className = 'deleteConnectButton';
      const handle = connect.querySelectorAll('.noUi-handle')[0] as HTMLDivElement;
      div.addEventListener('click', this.deleteSection);
      div.setAttribute('data-handle', handle.getAttribute('data-handle') + '');
      connect.append(div);
    };
    for (const connect of connectors) {
      addButton(connect as HTMLDivElement);
    }
  }

  private destroySlider() {
    if (// @ts-ignore
        this.slider().noUiSlider) {
      // @ts-ignore
      this.slider().noUiSlider.destroy();
    }
  }

  public updateSlider() {
    this.destroySlider();
    this.initSlider();

  }

  private cleanTimeLine() {
    // clean timeLine from CUT EVENT
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.END_CUT
          || te.type === TimeEventType.START_CUT) {
        store.dispatch(DELETE_TIME_EVENT, te);
      }
    }
  }

  private beforeDestroy() {
    this.destroySlider();
    this.cleanTimeLine();
  }
}
</script>

<style lang="scss">
//Customisation du composant noUiSlider
// pour gerer une timeline
@import '@/scss/shadows.scss';

$timeLineColorSelect: #4990e2;


.timeLineSelector {
  position: relative;
  height: 84px;
  margin-top: 8px;

  &.modeIntegration{
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .magicButton {
    text-align: left;
    margin-left: 8px;
    transition: all 0.2s ease;
  }

  .timeLineImageBackground {
    position: absolute;
    top: 32px;
    left: 16px;
    border-radius: 0px 0px 8px 8px;
    height: 56px;
    overflow: hidden;
    background: var(--light1);
    transition: width 0.2s ease;
    will-change: scroll-position;

  }

  .toolPanel {
    position: relative;
    top: -10px;
    display: flex;
    width: calc(100% - 32px);
    margin-left: 16px;

    .zoomSelector {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .zoomSelectorTimeLineContainer {
        min-width: 50px;
        display: flex;
        font-size: 12px;
        color: var(--dark);
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        margin-right: 8px;
      }

      .buttonZoom {
      }
    }
  }

  .scrollContainer {
    height: 108px;
    top: -8px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    .ps__rail-x {
      opacity: 1;

      .ps--clicking .ps__thumb-x {
        height: 12px;
      }

      .ps__thumb-x {
        height: 12px;
      }
    }
  }

  .timeLineBackground {
    position: absolute;
    top: 16px;
    left: 0;
    height: calc(100% - 16px);
    width: 100%;
    overflow: hidden;
    font-size: 20px;
    font-weight: 900;
    color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    // background: var(--light);
    .iconAdvice {
      margin-right: 8px;
    }
  }


  // GESTINO TIMELINE
  .timeline {
    will-change: scroll-position;
    height: calc(100% - 20px);
    position: absolute;
    top: 24px;
    margin-top: 8px;
    transition: width 0.2s ease;

    &.cut {
      cursor: url(@/assets/icons/content-cut.png), auto;
    }

    &.chapter {
      cursor: url(@/assets/icons/format-title.png), auto;
    }

    &.noUi-state-drag {
      cursor: grabbing;
    }


    // Background of the slider
    &.noUi-target {
      background: none;
      border: none;
      box-shadow: none;
      height: 56px;
    }

    .noUi-base {
      top: 20px;
      height: 0px;

      .noUi-connects {
        overflow: visible;
        //will-change: auto;

        .noUi-connect {
          height: 56px;
          margin-top: -20px;
          background: none;
          cursor: grab;
          opacity: 0.5;
          background: var(--red);
          background-image: url('@/assets/icons/scratch_texture.png');
          background-size: 64px 64px;
          background-repeat: repeat;
          backdrop-filter: grayscale(100%);
          border-radius: 4px;

          &:before {
            content: "";
            position: absolute;
            top: 0px;

          }

        }
      }
    }

    .noUi-pips-horizontal {
      height: 16px;
      cursor: pointer;
      top: -14px;
      padding: 0px;
      pointer-events: none;

      &.noUi-pips {
        color: var(--light2);
      }

      .noUi-marker-large {
        background: var(--light1);
        height: 6px;
        bottom: 0px;
      }

      .noUi-value-large {
        font-size: 12px;
        top: -18px;
        color: var(--light2);
        //background: var(--light);

      }

      .noUi-marker-normal {
        background: var(--light1);
        height: 4px;
        bottom: 0px;
      }
    }

    .noUi-origin {

      .deleteConnectButton {
        position: absolute;
        width: 16px;
        right: -8px;
        color: var(--red);
        border: solid 1px var(--red);
        top: -28px;
        height: 16px;
        background-color: var(--light);
        @extend .shadow1;
        background-image: url(@/assets/icons/cross_red.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 12px;
        cursor: pointer;
        border-radius: 50%;
        z-index: 10;
        opacity: 0;

        &:hover {
          opacity: 1;
          transform: scale(1.2);
        }
      }

      &.chapter {
        .deleteConnectButton {
          border: solid 1px var(--light2);
          background-image: url(@/assets/icons/cross_light2.png);
        }
      }

      .noUi-handle {
        border: 1px solid var(--red);
        border-radius: 50%;
        cursor: ew-resize;
        width: 24px;
        height: 24px;
        margin-right: 4.5px;
        top: -4px;
        transition: all 0.1s linear;
        @extend .shadow1;

        &:hover {
          border: 1px solid var(--red);
          // z-index: 100 !important;
          .noUi-tooltip {
            display: block;
            opacity: 1;
          }
        }

        &::before, &::after {
          background: none;
          content: "";
          display: block;
          position: absolute;
          height: 14px;
          width: 0px;
          left: 7.5px;
          top: 9px;
        }

        &:focus {
          outline: none;
        }

        .noUi-tooltip {
          display: none;
          pointer-events: none;
          transition: opacity 0.2s ease;
          opacity: 0;
          border: solid 1px var(--dark);
          border-radius: 4px;
          background: #fff;
          color: var(--dark);
          padding: 3px;
          text-align: center;
          white-space: nowrap;
          font-size: 12px;
          bottom: -32px;
        }

      }

      &.chapter {
        .noUi-handle {
          border: 1px solid var(--light2);
          background: var(--light2);
          background-image: url(@/assets/icons/format-title_white.png);
          background-repeat: no-repeat;
          background-size: 16px 16px;
          background-position: center center;

          &::after {
            background: none;
            content: "";
            display: block;
            position: absolute;
            height: 56px;
            width: 2px;
            background: var(--light2);
            left: 9.5px;
            top: -17px;
            z-index: -1;
          }
        }
      }

      &.left {
        .noUi-handle {
          opacity: 0;
          background-color: var(--red);
          background-image: url("@/assets/icons/double_pointer.png");
          background-repeat: no-repeat;
          background-size: 20px 20px;
          background-position: center center;
        }
      }

      &.right {
        .noUi-handle {
          opacity: 0;
          background-color: var(--red);
          background-image: url("@/assets/icons/double_pointer.png");
          background-repeat: no-repeat;
          background-size: 20px 20px;
          background-position: center center;
        }
      }
    }

    &:hover {
      .noUi-origin {
        .deleteConnectButton {
          opacity: 1;
        }

        .noUi-handle {
          opacity: 1;
        }
      }
    }
  }

  // GESTION CURSOR
  .timeLineCursorConteneur {
    cursor: pointer;
    background: var(--light);
    border-radius: 4px 4px 0px 0px;
    position: absolute;
    transition: width 0.2s ease;
    height: 24px;
    top: 8px;

    .dragableCursor {
      border: none;

      transition: transform 0.2s linear;
    }

    .dragableCursor-active {
      border: none;
      transition: none;
      cursor: grab;

      &.dragging {
        cursor: grabbing;
      }
    }

    .timeLineCursor {
      will-change: contents;
      position: absolute;
      height: 62px;
      border: 2px solid var(--primary);
      width: 0px;
      @extend .shadow1;
      top: 20px;
      pointer-events: none;
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.3);

      &:before {
        content: "";
        position: absolute;
        width: 16px;

        pointer-events: visible;
        display: flex;
        align-content: center;
        justify-content: center;
        height: 16px;
        top: -17px;
        left: -8px;

        background: var(--primary);
        border-radius: 40px;
        @extend .shadow1;

      }
    }
  }

  .timeLineMouseCursor {
    will-change: contents;
    position: absolute;
    height: 56px;
    border: 1px dashed var(--dark);
    width: 0px;
    margin-left: 16px;
    top: 32px;
    pointer-events: none;
    // z-index: 2;
    background: rgba(255, 255, 255, 0.3);
  }

  .tooltipMousePosition {
    will-change: contents;
    position: absolute;
    top: 84px;
    font-size: 12px;
    color: var(--dark);
    border: 1px solid var(--dark);
    border-radius: 4px;
    // width: 32px;
    padding: 2px;
    margin-left: -10px;
    pointer-events: none;
    background: white;
    min-width: 54px;
    z-index: 2;
  }


}

</style>
