import {Weet} from "@/store/weet/weetModel";
import {
    getMediaForTimeEvent,
    getObsoleteMedia,
    getTimeEventByType,
    getTimeEventExactTime, getTimeEventOfSection,
    timeOfLastSection
} from "@/utils/timeLineUtils";
import {TimeEventType} from "@/enum/TimeEventType";
import {log} from "@/utils/log";
import store from "@/store";
import {METADATA_MEDIA} from "@/store/media/mediaAction";
import {
    ADD_TIME_EVENT,
    DELETE_TIME_EVENT, DETTACHED_OBSOLETE_MEDIA, REMOVE_SECTION_EVENT,
    SAVE_TIMELINE_IF_NEED,
    TIMELINE_UNSAVED
} from "@/store/timeLine/timeLineAction";
import {informError} from "@/utils/dialog";
import {i18n} from "@/utils/i18nUtils";
import {clone} from "@/utils/cloneUtil";

export const recoverWeet=async (weetToRecover:Weet):Promise<Weet>=>{
    // weet has no timeline but media
    if(weetToRecover.timeLine.length===0){
        if(weetToRecover.medias.length>0) {
            weetToRecover=await weetHasObsoleteMedia(weetToRecover);
        }
    }
    if(getObsoleteMedia(weetToRecover.timeLine,weetToRecover.medias).length>0) {
        weetToRecover=await weetHasObsoleteMedia(weetToRecover);
    }

    // End recover without timeline
    if(weetToRecover.timeLine.length===0){
        return weetToRecover;
    }

    // check if weet has pause event
    const eventPause=getTimeEventByType(weetToRecover,TimeEventType.MEDIA_PAUSE)
    if(eventPause.length===0){
        weetToRecover=await weetHasNoPause(weetToRecover);
    }

    // check if weet has a pause in last event
    const lastEvent=weetToRecover.timeLine[weetToRecover.timeLine.length-1];
    if(lastEvent.type!==TimeEventType.MEDIA_PAUSE){
        weetToRecover=await weetHasNoPauseAtTheEnd(weetToRecover);
    }
    weetToRecover=await checkMediaErrorSection(weetToRecover)


    return weetToRecover;
}


/**
 * Recover weet without Pause event
 * @param weetToRecover
 */
const weetHasNoPause=async (weetToRecover:Weet)=>{
    log.warn('WeetHasNoPause',weetToRecover)
    const timeEventLastMedia=getTimeEventExactTime(weetToRecover.timeLine,await timeOfLastSection(weetToRecover.timeLine));
    if(timeEventLastMedia) {
        // get mainMedia
        const mainMedia=getMediaForTimeEvent(weetToRecover, timeEventLastMedia, true);
        // get secondMedia
        const secondMedia=getMediaForTimeEvent(weetToRecover, timeEventLastMedia, false);
        const media=secondMedia||mainMedia;
        if(media){
            const metaData=await store.dispatch(METADATA_MEDIA, media.mediaID);
            const pauseEvent=clone(timeEventLastMedia)
            pauseEvent.type=TimeEventType.MEDIA_PAUSE;
            pauseEvent.time+=metaData.duration*1000;
            await store.dispatch(ADD_TIME_EVENT,pauseEvent);
            await store.dispatch(TIMELINE_UNSAVED);
            await store.dispatch(SAVE_TIMELINE_IF_NEED);
            weetToRecover.timeLine=store.getters.getTimeEvent;
            return weetToRecover;
        }
    }

    log.warn("Impossible to recover the WeetHasNoPause. You weet seems broken")
    return weetToRecover;
}

// change the position of the pause Event
const weetHasNoPauseAtTheEnd=async (weetToRecover:Weet)=>{
    log.warn('WeetHasNoPauseAtTheEnd',weetToRecover)
    let needToDelete=false;
    for(const timeE of weetToRecover.timeLine){
        if(needToDelete){
            await store.dispatch(DELETE_TIME_EVENT,timeE);
        }
        if(timeE.type===TimeEventType.MEDIA_PAUSE){
            // now save the timeline
            needToDelete=true;
        }
    }
    await store.dispatch(TIMELINE_UNSAVED);
    await store.dispatch(SAVE_TIMELINE_IF_NEED);
    weetToRecover.timeLine=store.getters.getTimeEvent;
    return weetToRecover;
}

/**
 * Remove obsolete media of the weet
 * @param weetToRecover
 */
const weetHasObsoleteMedia=async (weetToRecover:Weet)=>{
    log.warn('WeetHasObsoleteMedia',weetToRecover)
    await store.dispatch(DETTACHED_OBSOLETE_MEDIA)
    weetToRecover.medias=store.getters.getMedias;
    return weetToRecover;
}



const checkMediaErrorSection=async (weetToRecover:Weet)=>{
    // check media in error
    for(const timeEventSection of getTimeEventOfSection(weetToRecover.timeLine,0)){
        // get mainMedia
        const mainMedia=getMediaForTimeEvent(weetToRecover, timeEventSection, true);
        // get secondMedia
        const secondMedia=getMediaForTimeEvent(weetToRecover, timeEventSection, false);

        if(mainMedia){

            if(mainMedia.status!="HD" && Date.now()-mainMedia.created >600000){
                log.warn("Error on media, delete the section"+mainMedia.mediaID)
                await store.dispatch(REMOVE_SECTION_EVENT,timeEventSection.time);
                informError(i18n.t("createWeetComponent.error.deleteSection").toString())
                weetToRecover.medias=store.getters.getMedias;
                weetToRecover.timeLine=store.getters.getTimeEvent;
                continue;
            }
        }
        if(secondMedia){
            if(secondMedia.status!="HD" && Date.now()-secondMedia.created >600000){
                log.warn("Error on media, delete the section"+secondMedia.mediaID)
                await store.dispatch(REMOVE_SECTION_EVENT,timeEventSection.time);
                weetToRecover.medias=store.getters.getMedias;
                weetToRecover.timeLine=store.getters.getTimeEvent;
                informError(i18n.t("createWeetComponent.error.deleteSection").toString())
            }
        }
    }
    return weetToRecover;
}
