<template>
  <div class="weetSettingContainer">

    <b-tabs class="tabsRecorderSharing" position="is-left" v-model="tabSelected">
      <!-- ITEM SHARE -->
      <b-tab-item>
        <template #header>
          <span>{{ $t('panelPeople.tabShare.title') }}</span>
        </template>
        <div class="errorBox">
          <w-error :message="errormsg"/>
        </div>
        <perfect-scrollbar :options="{suppressScrollX:true}" class="scrollContainer">
          <div class="workspaceSelectionContainer" v-if="getNumberOfWorkspace>0">
            <InputWorkspaceChoose @select="onSelectWorkspace" :value="selectedWorkspaceID"
                                  :minWidth="'268px'"/>
          </div>
          <div class="contentPanel">
            <!-- CHAMPS INPUT -->
            <div class="inputContent">
              <div class="labelInput">{{ getPlaceHolder }}</div>
              <div class="inputLine">
                <ShareContactInput size="middle" ref="shareContactInput" @userCommit="userAddToSHare"
                                   class="inputContact"
                                   :internal-user="true" :channel="true" :external-user="true"
                                   :place-holder="getPlaceHolder" :max-height="250"/>
              </div>
            </div>
          </div>
          <!-- CHANNEL -->
          <transition name="fade">
            <div class="channelContainer" v-if="selectedWorkspaceID"
                 v-show="listOfChannel.length>0">
              <div class="labelChannel">{{ $t('panelPeople.tabShare.channels.title') }}</div>
              <transition-group name="list-complete" class="channelLine" v-if="listOfChannel.length>0">
                <ChannelWorkspace v-for="channel in listOfChannel"
                                  :key="'channelPeople'+channel.channelID" class="channelItem list-complete-item"
                                  :channel="channel" :selectable="false" :deleteAction="true"
                                  @delete="removeChannel"/>
              </transition-group>
              <div v-else class="emptyChannel">
                {{ $t('panelPeople.tabShare.channels.empty') }}
              </div>
            </div>
          </transition>

          <!-- DirectSharing -->
          <transition name="fade">
            <div class="ownerContainer" v-show="listOfOwner.length>1">
              <div class="labelOwners">{{ $t('panelPeople.tabShare.owners.title') }}</div>
              <transition-group name="list-complete" class="listOfUser">
                <div v-for="owner in listOfOwner" :key="'owner'+owner.user.userID" v-if="!isMe(owner)"
                     class="list-complete-item">
                  <UserAuthorization size="small" :user-share="owner" :weet="myWeet" @error="onAuthorizationChangeError"
                                     @updated="updateListOwner"/>
                </div>
              </transition-group>
            </div>
          </transition>
        </perfect-scrollbar>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <w-icon :icon="isPrivate?'lock-outline':'link'" size="small"/>
          <span>{{ $t('panelPeople.tabSetting.title') }}</span>
        </template>
        <div class="contentPanelShareLink">
          <div class="shareLink" v-if="myWeet &&  tabSelected===1">
            <ShareVisibilityPolicy :weet="myWeet"/>
          </div>
          <div class="linkBar">
            <CopyThumbnailWeetButton :weet="myWeet" size="small" color="outlined"/>
            <w-button class="buttonCopy" :expanded="true" icon="link" color="outlined" size="small" @click="copyLink">{{
                $t('createWeetComponent.settings.copy')
              }}
            </w-button>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <div class="buttonSaveContainer">
      <div class="bottomOpaqueLayout"></div>
      <EndWeetButton steptour="finish" :color="isShareEmpty?'primary':'primary'" class="buttonValidate"
                     :expanded="true" @click="finishEditing"
                     :loading="loading" v-if="this.listContactToShare.length===0">
        {{ getFinishLabel }}
      </EndWeetButton>

      <w-button class="sendButton"
                @click="inviteUser" icon="send" :expanded="true"
                v-if="this.listContactToShare.length>0"
                :loading="loading">{{
          $t('createWeetComponent.settings.send').toString()
        }}
      </w-button>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import WField from '@/components/wrapper/w-field.vue';
import ShareContactInput from '@/components/share/ShareContactInput.vue';
import WButton from '@/components/wrapper/w-button.vue';
import ShareVisibilityPolicy from '@/components/share/ShareVisibilityPolicy.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import {ADD_WEET_TO_WORKSPACE, REFRESH_MY_WEET, SET_CHANNELS_TO_WEET} from '@/store/myWeet/myWeetAction';
import WorkspaceSelection from '@/components/workspace/WorkspaceSelection.vue';
import {selectWorkspace, WORKSPACE_CHANNEL_FILTER} from '@/utils/workspaceUtil';
import {SET_CHANNELS_FOR_EDITING} from '@/store/recordingState/recordStateAction';
import WError from '@/components/wrapper/w-error.vue';
import InputWorkspaceChoose from '@/components/workspace/InputWorkspaceChoose.vue';
import PopUpTitle from '@/components/title/PopUpTitle.vue';
import ChannelWorkspace from '@/components/channel/ChannelWorkspace.vue';
import {Weet, WeetOwer} from '@/store/weet/weetModel';
import {copyLink, createUnsavedEditingWeet, weetLink} from '@/utils/weetUtil';
import {Channel} from '@/store/channel/channelModel';
import {confirmation, inform} from '@/utils/dialog';
import UserAuthorization from '@/components/share/UserAuthorization.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import {DEFAULT_WEET_TITLE} from '@/store/conf/api';
import { isEmail} from '@/utils/util';
import {AuthorisationEnum} from '@/enum/AuthorisationEnum';
import {SHARE_A_WEET, ShareParams} from '@/store/weet/weetAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import uniq from 'lodash/uniq';
import {VisibilityPolicy} from '@/store/weet/VisibilityPolicy';
import {User} from '@/store/user/userModel';
import delay from 'delay';
import CopyThumbnailWeetButton from '@/components/actionButton/CopyThumbnailWeetButton.vue';
import {clone} from "@/utils/cloneUtil";


@Component({
  components: {
    CopyThumbnailWeetButton,
    EndWeetButton,
    UserAuthorization,
    ChannelWorkspace,
    PopUpTitle,
    InputWorkspaceChoose,
    WorkspaceSelection,
    WIcon, ShareVisibilityPolicy, WButton, ShareContactInput, WField, WError
  },
})
export default class CreateWeetSettingStep extends Vue {
  private errormsg = '';
  private loading: boolean = false;

  private listContactToShare: string[] = [];

  private listOfOwner: WeetOwer[] = [];
  private listOfChannel: Channel[] = [];

  private tabSelected: number = 0;

  private visibility: VisibilityPolicy = VisibilityPolicy.SEMI_PRIVATE;

  get weetID(): string {
    return store.getters.getEditingWeetID;
  }

  get myWeet(): Weet {
    return createUnsavedEditingWeet(this.weetID);
  }

  get isShareEmpty(): boolean {
    return (this.listOfOwner.length === 1 && this.listOfChannel.length === 0);
  }

  get weetLink(): string {
    if (this.myWeet) {
      return weetLink(this.myWeet);
    } else {
      return '';
    }
  }

  get isFinishAivailable(): boolean {
    return (!store.getters.isSychronisationInProgress && !store.getters.timelineNeedToBeSaved);
  }

  get getFinishLabel(): string {
    if (!this.isShareEmpty) {
      return this.$t('createWeetComponent.settings.finish').toString();
    } else {
      return this.$t('createWeetComponent.settings.finishLater').toString();
    }
  }


  get getNumberOfWorkspace(): number {
    return store.getters.getMyWorkspaces.length;
  }

  get selectedWorkspaceID(): string | null {
    return store.getters.getSelectedWorkspaceID;
  }

  get getPlaceHolder(): string {
    if (!this.selectedWorkspaceID) {
      return this.$t('createWeetComponent.share.inviteUserPlaceholder').toString();
    } else {
      return this.$t('createWeetComponent.share.addUserPlaceholder').toString();
    }
  }

  get isPrivate(): boolean {
    return this.myWeet.visibilityPolicy === VisibilityPolicy.INTERNAL;
  }

  @Watch('selectedWorkspaceID')
  private resetChannel() {
    this.listOfChannel = [];
  }

  private isMe(owner: WeetOwer): boolean {
    const me: User = store.getters.userConnected;
    return me?.userID === owner.user.userID;
  }

  private mounted() {
    this.updateListOwner(this.myWeet);
    this.updateChannel(this.myWeet);
  }

  private updateListOwner(weet: Weet) {
    // console.log(weet.owners);
    // console.log(weet.owners.length);
    if (weet.owners.length > 0) {
      this.listOfOwner = clone(weet.owners) as WeetOwer[];
      this.listOfOwner = this.listOfOwner.reverse();
    } else {
      this.listOfOwner = [];
    }


  }

  private copyLink() {
    if (this.myWeet) {
      copyLink(this.myWeet);
    }
    inform(this.$t('createWeetComponent.share.confirm').toString());
  }

  private updateChannel(weet: Weet) {
    if (weet.channels.length > 0) {
      this.listOfChannel = clone(weet.channels) as Channel[];
    } else {
      this.listOfChannel = [];
    }
  }


  private async finishEditing() {
    this.loading = true;
    await delay(1000);
    this.$emit('end');
    this.loading = false;
  }

  private async inviteUser() {

    if (this.myWeet && this.myWeet.title === DEFAULT_WEET_TITLE) {
      try {
        await confirmation(this.$t('createWeetComponent.settings.titleValidate.send.validateTitleDescription', {weetTitle: this.myWeet.title}).toString(),
            this.$t('createWeetComponent.settings.titleValidate.send.validateTitleTitle').toString());
      } catch (e) {
        return;
      }
    }
    let numberCallBackToWait = 0;
    this.loading = true;
    let emailsToShare = this.listContactToShare.map((contactOrEmail: any) => {
      return (typeof contactOrEmail === 'string') ? contactOrEmail : contactOrEmail.contactEmail;
    });
    emailsToShare = emailsToShare.filter((value) => {
      return isEmail(value);
    });

    if (emailsToShare.length > 0) {
      const authorisationRight = AuthorisationEnum.COMMENT;
      const params = new ShareParams(this.myWeet.weetID, emailsToShare, authorisationRight);
      numberCallBackToWait++;
      store.dispatch(SHARE_A_WEET, params).then((data: Weet) => {
        this.loading = false;
        this.errormsg = '';
        inform(this.$t('shareWeet.confirm').toString());
        setActionEvent(ActionEvent.share_send_contact, {
          number_of_contact: this.listContactToShare.length,
          shared_contacts: this.listContactToShare
        });

        numberCallBackToWait--;
        if (numberCallBackToWait === 0) {
          this.resetInput();
        }
        this.updateListOwner(data);
      }).catch((err) => {
        this.loading = false;
        this.errormsg = err;
      });
    }

    // filter channel
    let listChannelToAdd: any[] = this.listContactToShare.filter((e) => {
      return e.startsWith(WORKSPACE_CHANNEL_FILTER);
    });

    listChannelToAdd = listChannelToAdd.map((e) => {
      return store.getters.getChannelByName(e.replace(WORKSPACE_CHANNEL_FILTER, ''));
    });
    // now we concanate with the list of channel of the weet
    listChannelToAdd = listChannelToAdd.concat(this.myWeet.channels);
    // now we transform on list of ids

    listChannelToAdd = listChannelToAdd.map((e) => {
      return e.channelID;
    });
    listChannelToAdd = uniq(listChannelToAdd);
    if (listChannelToAdd.length > 0) {
      numberCallBackToWait++;
      store.dispatch(SET_CHANNELS_TO_WEET, {
        weetID: this.myWeet.weetID,
        channelIDs: listChannelToAdd
      }).then((data: Weet) => {
        this.loading = false;
        if (emailsToShare.length === 0) {
          inform(this.$t('shareWeet.confirm').toString());
        }
        numberCallBackToWait--;
        if (numberCallBackToWait === 0) {
          this.resetInput();
        }
        this.updateChannel(data);
      });
    }
  }

  private resetInput() {
    (this.$refs.shareContactInput as ShareContactInput).reset();
  }

  private removeChannel(channel: Channel) {
    // get list of Channel of weet
    const listOfChannel: string[] = [];
    for (const channelWeet of this.myWeet.channels) {
      if (channelWeet.channelID !== channel.channelID) {
        listOfChannel.push(channelWeet.channelID);
      }
    }

    store.dispatch(SET_CHANNELS_TO_WEET, {
      weetID: this.weetID,
      channelIDs: listOfChannel
    }).then((data: Weet) => {
      this.updateChannel(data);
      inform(this.$t('panelPeople.tabShare.channels.success').toString());
    });
  }

  private userAddToSHare(values: string[]) {
    this.listContactToShare = values;
  }

  private onAuthorizationChangeError(error: string) {
    this.errormsg = error;
  }


  private onSelectWorkspace(workspaceID: string | null) {
    // Add the weet to workspace we selected workspace change
    this.errormsg = '';
    const weet2Save = clone(this.myWeet);
    store.dispatch(ADD_WEET_TO_WORKSPACE,
        {
          weetID: store.getters.getEditingWeetID,
          workspaceID: workspaceID,
        }).then(() => {
      selectWorkspace(workspaceID);
      store.dispatch(REFRESH_MY_WEET, weet2Save);
      this.resetInput();
    }).catch((error: any) => {
      this.errormsg = error.message;
    });
    store.dispatch(SET_CHANNELS_FOR_EDITING, []);
  }

}
</script>
<style lang="scss">
.tabsRecorderSharing {
  margin-top: 24px;

  .tabs {
    margin-left: 16px;
    margin-right: 16px;
  }
}

</style>
<style scoped lang="scss">

.weetSettingContainer {


  .mainTitleShare {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .scrollContainer {
    height: 430px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .bottomOpaqueLayout {
    background: red;
    width: 100%;
    height: 80px;
    bottom: 50px;
    left: 0;
    position: absolute;
    pointer-events: none;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .buttonSaveContainer {
    position: absolute;
    bottom: 24px;
    margin-left: 16px;
    width: calc(100% - 32px);

  }

  .contentPanel {
    padding-top: 16px;
  }

  .inputContent {
    .labelInput {
      font-size: 16px;
      color: var(--dark);
      text-align: left;
      margin-bottom: 8px;
    }

    .inputLine {
      display: flex;

      .inputContact {
        flex: 1;
      }
    }
  }

  .channelContainer {

    .labelChannel {
      font-size: 16px;
      color: var(--dark);
      text-align: left;
      margin-top: 16px;
    }

    .channelLine {
      display: flex;
      flex-wrap: wrap;

      .channelItem {
        margin-top: 8px;
      }
    }

    .emptyChannel {
      color: var(--light2);
      font-size: 12px;
      text-align: left;
    }
  }

  .ownerContainer {
    padding-bottom: 80px;

    .labelOwners {
      font-size: 16px;
      color: var(--dark);
      text-align: left;
      margin-top: 16px;
    }

    .listOfUser {
      text-align: left;
    }
  }

  .contentPanelShareLink {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
  }

  .linkBar {
    display: flex;
    width: 100%;
    max-width: 300px;

    .buttonCopy {
      margin-left: 8px;
    }
  }
}

</style>
